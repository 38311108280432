/*
 ** TailwindCSS Configuration File
 **
 ** Docs: https://tailwindcss.com/docs/configuration
 ** Default: https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
 */

module.exports = {
  darkMode: 'class',
  important: true,
  corePlugins: {
    placeholderColor: false,
  },
  content: [
    './public/**/*.html',
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/containers/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    container: {
      center: true,
    },
    screens: {
      desktop: '1280px',
    },
    fontFamily: {
      sans: [
        'Helvetica Neue',
        'Arial',
        'Hiragino Kaku Gothic ProN',
        'Noto Sans',
        'Meiryo',
        'sans-serif', // どれも見つからなかった場合のフォールバック用
      ],
    },
    fontSize: {
      '3xs': ['0.5rem', '12px'],
      '2xs': ['0.625rem', '15px'],
      xs: ['0.75rem', '18px'],
      sm: ['0.875rem', '21px'],
      base: ['1rem', '24px'],
      lg: ['1.125rem', '27px'],
      xl: ['1.25rem', '30px'],
      '2xl': ['1.5rem', '36px'],
      '3xl': ['1.75rem', '42px'],
      '4xl': ['1.875rem', '45px'],
    },
    extend: {
      colors: {
        orange: {
          10: '#fefaf2',
          20: '#fff4e2',
          30: '#f3ba5a',
          40: '#f5ad33',
          50: '#f39800',
        },
        brown: {
          10: '#f7f4ef',
          20: '#e8e2d9',
          30: '#c6b7a1',
          40: '#aa987b',
          50: '#ada495',
          60: '#af8d55',
          70: '#837257',
          80: '#6b5533',
        },
        red: {
          10: '#fef6f5',
          20: '#feeceb',
          30: '#eeaaaa',
          40: '#e57373',
          50: '#f44336',
        },
        yellow: {
          10: '#fffae9',
          20: '#fff0a1',
          30: '#e8c127',
        },
        blue: {
          10: '#43bccd',
          20: '#418ce3',
          30: '#8ed7ed',
          40: '#64b5f6',
          50: '#e3f2fd',
        },
        gray: {
          10: '#fcfcfc',
          20: '#f9f9f9',
          30: '#f5f5f5',
          40: '#e5e5e5',
          50: '#cccccc',
          60: '#c5c5c5',
          70: '#999999',
          80: '#888888',
          90: '#666666',
          100: '#444444',
          110: '#333333',
          120: '#121212',
        },
        bluegray: {
          10: '#f5f6fa',
        },
        legends: {
          // 単語
          word: '#e57373',
          'word-l': '#ffebee',

          // 音声知覚
          'speech-perception': '#64b5f6',
          'speech-perception-l': '#e3f2fd',

          // 概念化
          conceptualization: '#81c784',
          'conceptualization-l': '#c8e6c9',

          // 実践力
          'practical-ability': '#a1887f',
          'practical-ability-l': '#d7ccc8',

          // 文法
          grammar: '#9575cd',
          'grammar-l': '#ffccbc',

          // 意味理解
          understanding: '#4db6ac',
          'understanding-l': '#b2dfdb',

          // 文章化
          formalization: '#ff8a65',
          'formalization-l': '#ffccbc',

          // その他
          other: '#90a4ae',
          'other-l': '#cfd8dc',
        },
        // TODO: darkモード導入にあたり廃止にしたい
        error: '#f44336',
        success: '#43bccd',
        good: '#418CE3',
        development: '#E57373',
      },
    },
  },
  plugins: [],
};
