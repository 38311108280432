import { Container, CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import type { SWRConfiguration } from 'swr';
import { SWRConfig } from 'swr';

import { GlobalStyles } from 'twin.macro';

import { ErrorBoundary } from '~/components/ErrorBoundary';
import { Loading } from '~/components/Loading';
import { MyAppBar } from '~/components/MyAppBar';
import { AuthMiddleware } from '~/containers/AuthMiddleware';
import { RestrictBrowserMiddleware } from '~/containers/RestrictBrowserMiddleware';
import { ThemeProvider } from '~/containers/ThemeProvider';
import { setShouldConfirmNavigation } from '~/hooks/useConfirmNavigation';

import 'dayjs/locale/ja';
import '~/styles/globals.css';

dayjs.locale('ja');

const FOCUS_TIME_KEY = 'FOCUS_TIME_KEY';
const MAX_FOCUS_INTERVAL = 60 * 60 * 24 * 1000;

const MyApp = ({ Component, pageProps }: AppProps) => {
  // NOTE: "Did not expect server HTML to contain a <div> in <div>." が出ないようにisReadyを見る
  const router = useRouter();
  const [isReady, setIsReady] = useState(false);
  // NOTE: Hydration エラー対策のためクライアント側でも初回レンダリングはSSRと同じDOMになるようにする
  useEffect(() => {
    setIsReady(router.isReady);
  }, [router.isReady]);

  return (
    <div className="flex" data-testid="app-container">
      <Loading />
      <MyAppBar />
      <main className="flex-grow">
        <div className="h-[var(--appbar-h)]" />
        <Container maxWidth={false} disableGutters classes={{ root: 'mx-0' }}>
          <RestrictBrowserMiddleware>
            {isReady && (
              <AuthMiddleware>
                <Component {...pageProps} />
              </AuthMiddleware>
            )}
          </RestrictBrowserMiddleware>
        </Container>
      </main>
    </div>
  );
};

const Root = (appProps: AppProps) => {
  useEffect(() => {
    // NOTE: Next.js & material UIで[Prop className` did not match`]が発生する問題への対処
    // https://qiita.com/sobameshi0901/items/a3a95b88770a52f1f31c
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const swrConfigValue: SWRConfiguration = useMemo(
    () => ({
      onError(error) {
        throw error;
      },
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      dedupingInterval: 30000,
      revalidateOnMount: true,
    }),
    []
  );

  useEffectOnce(() => {
    const onFocus = async () => {
      const prev = sessionStorage.getItem(FOCUS_TIME_KEY);
      const now = Date.now();
      if (
        prev != null &&
        !isNaN(Number(prev)) &&
        now - Number(prev) > MAX_FOCUS_INTERVAL
      ) {
        await setShouldConfirmNavigation('interview', false);
        window.location.reload();
      }

      sessionStorage.setItem(FOCUS_TIME_KEY, `${now}`);
    };
    window.addEventListener('focus', onFocus);
    if (sessionStorage.getItem(FOCUS_TIME_KEY) == null) {
      sessionStorage.setItem(FOCUS_TIME_KEY, `${Date.now()}`);
    }
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  });

  return (
    <ErrorBoundary>
      {/* TODO: swrをアップデートしてignoreコメントを削除する */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SWRConfig value={swrConfigValue}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <GlobalStyles />
            <CssBaseline enableColorScheme />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MyApp {...appProps} />
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
};

export default Root;
