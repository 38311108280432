import {
  createTheme,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles';

import tw from 'twin.macro';

import tailwindTheme from '../../../tailwind.config';

const {
  extend: { colors },
  fontSize,
} = tailwindTheme.theme;

// NOTE: `Warning: findDOMNode is deprecated in StrictMode` が出る問題への対策
// https://stackoverflow.com/a/64135466
const createThemeFunction =
  process.env.ENV === 'local' ? unstable_createMuiStrictModeTheme : createTheme;

export const getTheme = (mode: 'light' | 'dark') => {
  const color = mode === 'light' ? colors.gray['110'] : colors.gray['40'];
  const onlyLight = <V = unknown>(value: V) =>
    mode === 'light' ? value : undefined;
  const onlyDark = <V = unknown>(value: V) =>
    mode === 'dark' ? value : undefined;

  return createThemeFunction({
    palette: {
      mode,
      text: {
        primary: color,
      },
      primary: {
        main: colors.orange['50'],
        contrastText: mode === 'light' ? '#FFFFFF' : colors.gray['120'],
      },
      secondary: {
        main: mode === 'light' ? 'rgba(0, 0, 0, 0)' : colors.gray['40'],
        contrastText:
          mode === 'light' ? colors.gray['110'] : colors.gray['120'],
      },
      action: {
        hover: mode === 'light' ? colors.gray['30'] : colors.gray['110'],
        disabledBackground: mode === 'light' ? colors.gray['60'] : undefined,
        disabled: mode === 'light' ? colors.gray['110'] : colors.gray['70'],
      },
      divider: colors.gray['40'],
      error: { main: mode === 'light' ? colors.error : colors.red['30'] },
      default: {
        main: color,
      },
      background: {
        paper: mode === 'light' ? '#FFFFFF' : colors.gray['120'],
      },
    },
    typography: {
      h1: {
        fontSize: fontSize['2xl'][0],
        lineHeight: fontSize['2xl'][1],
        fontWeight: 'bold',
      },
      h2: {
        fontSize: fontSize.xl[0],
        lineHeight: fontSize.xl[1],
        fontWeight: 'bold',
      },
      h3: {
        fontSize: fontSize.base[0],
        lineHeight: fontSize.base[1],
        fontWeight: 'bold',
      },
      h4: {
        fontSize: fontSize.sm[0],
        lineHeight: fontSize.sm[1],
        fontWeight: 'bold',
      },
      body1: { fontSize: fontSize.base[0], lineHeight: fontSize.base[1] },
      body2: { fontSize: fontSize.base[0], lineHeight: fontSize.base[1] },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: 'var(--appbar-h)',
            backgroundColor: mode === 'light' ? '#FFFFFF' : colors.gray['120'],
            backgroundImage: 'none',
            boxShadow: onlyDark('none'),
            borderBottomWidth: onlyDark(1),
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 14,
            // NOTE: https://github.com/mui/material-ui/issues/28691 対応
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              '&': tw`opacity-100`,
              color: mode === 'light' ? colors.gray['110'] : colors.gray['40'],
            },
          },
          root: {
            color,
            '&.Mui-disabled': {
              backgroundColor: onlyLight(colors.gray['40']),
              border: onlyLight(colors.gray['60']),
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'default',
        },
        styleOverrides: {
          root: {
            minWidth: 120,
            whiteSpace: 'nowrap',
            textTransform: 'none', // NOTE: アルファベットが大文字にならないように
          },
          containedPrimary: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: colors.orange['40'],
              color: theme.palette.primary.contrastText,
            },
            '&:disabled': {
              backgroundColor: colors.brown['20'],
              color: onlyLight('#ffffff'),
            },
          }),
          containedSecondary: {
            border: onlyLight(`1px solid ${colors.gray['40']}`),
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: colors.gray['30'],
            },
            '&:disabled': {
              color: onlyLight(colors.gray['60']),
              backgroundColor: '#FFFFFF',
            },
          },
          sizeSmall: {
            height: 32,
          },
          sizeLarge: {
            width: 240,
            height: 48,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthSm: {
            maxWidth: 400,
          },
          paperWidthMd: {
            maxWidth: 700,
          },
          paperWidthLg: {
            maxWidth: 1000,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingTop: 32,
            paddingRight: 32,
            paddingLeft: 32,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingRight: 32,
            paddingLeft: 32,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingBottom: 32,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            '&:hover': {
              backgroundColor: `${colors.orange['20']} !important`, // NOTE: palette.action.hoverと競合しないようにimportantをつける
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor:
              mode === 'light' ? colors.gray['40'] : colors.gray['120'],
            borderBottom: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color,
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
        styleOverrides: {
          select: {
            paddingTop: 12,
            paddingLeft: 12,
            paddingRight: 12,
            paddingBottom: 8,
          },
        },
      },
      MuiSlider: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          colorPrimary: {
            '.MuiSlider-rail': {
              backgroundColor: colors.orange['20'],
              opacity: 1,
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            width: 60,
            flexShrink: 0,
          },
          paper: {
            width: 60,
            backgroundColor: onlyLight(colors.orange['50']),
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          colorPrimary: tw`text-gray-60`,
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-disabled': {
              backgroundColor: theme.palette.action.disabledBackground,
              opacity: 1,
            },
          }),
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: ({ ownerState, theme }) => {
            if (ownerState.color !== 'primary') return undefined;
            return {
              color:
                mode === 'light'
                  ? theme.palette.primary.contrastText
                  : theme.palette.primary.main,
            };
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            color: mode === 'light' ? '#FFF' : color,
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              borderColor: onlyLight(colors.gray['40']),
              backgroundColor: colors.orange['50'],
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: mode === 'light' ? colors.gray['110'] : colors.gray['40'],
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              border: 'none',
            },
          }),
        },
      },
    },
    zIndex: {
      drawer: 20,
    },
  });
};
