import type { AxiosRequestConfig } from 'axios';
import axios, { isAxiosError } from 'axios';
import type { JwtPayload } from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

import { api } from '~/api/index';

let alreadyRefreshRequest: Promise<string> | null = null;

export const getToken = async (config: AxiosRequestConfig): Promise<string> => {
  const token = window.localStorage.getItem('jwt');

  if (token == null)
    // eslint-disable-next-line import/no-named-as-default-member
    throw new axios.Cancel('認証トークンがセットされていません');

  // リフレッシュトークンを取得させないURL
  if (config.url === '/v1/auth/refresh' || config.url === '/v1/auth/logout')
    return token;

  // すでにリフレッシュトークンリクエストをしているか確認
  if (alreadyRefreshRequest) {
    return await alreadyRefreshRequest;
  }

  try {
    // 有効期限チェック
    const decoded = jwtDecode<JwtPayload>(token);
    if (decoded.exp != null && decoded.exp < Date.now() / 1000) {
      // リフレッシュトークン取得
      try {
        alreadyRefreshRequest = api.v1.auth.refresh.post().then((res) => {
          window.localStorage.setItem('jwt', res.body.access_token);
          return res.body.access_token;
        });
        return await alreadyRefreshRequest;
      } finally {
        alreadyRefreshRequest = null;
      }
    }
  } catch (error) {
    if (isAxiosError(error)) throw error;
    // eslint-disable-next-line import/no-named-as-default-member
    throw new axios.Cancel('認証トークンが不正です');
  }
  return token;
};
