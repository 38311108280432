import { useMediaQuery } from '@mui/material';
import { useGlobalState } from '@progrit/frontend-common';
import { useCallback, useEffect, useState } from 'react';

export const MODES = ['light', 'dark', 'system'] as const;
export type Mode = (typeof MODES)[number];

export const validateMode = (mode: string): mode is Mode =>
  MODES.includes(mode as unknown as Mode);

export const useDesignSystem = () => {
  const { data: themeSetting, mutate } = useGlobalState<Mode>(
    'theme',
    'system',
    {
      syncLocalStorage: true,
    }
  );
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [theme, _setTheme] = useState<'light' | 'dark'>('light');
  const setThemeSetting = useCallback(
    (theme: string) => {
      if (validateMode(theme)) {
        mutate(theme);
        if (theme === 'system') {
          _setTheme(prefersDarkMode ? 'dark' : 'light');
        }
      }
    },
    [mutate, prefersDarkMode]
  );

  useEffect(() => {
    if (themeSetting == null) return;
    if (themeSetting === 'system') {
      _setTheme(prefersDarkMode ? 'dark' : 'light');
      return;
    }
    _setTheme(themeSetting);
  }, [prefersDarkMode, themeSetting]);

  return {
    theme,
    themeSetting,
    setThemeSetting,
  };
};
