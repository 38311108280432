import aspida from '@aspida/axios';

import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import $api from './aspida/$api';

import { getToken } from '~/api/getToken';

import { mutateLoadingCount } from '~/hooks/useLoading';
import { isIgnoreHttpException } from '~/shared/sentry/rules';

export * from './aspida/@types';
import { captureFactory } from '~/shared/sentry/sentryCapture';
import { isAxiosErrorExactly } from '~/shared/utils';

const PUBLIC_APIS = [
  '/v1/auth/apply',
  '/v1/auth/verify',
  '/v1/auth/register',
  '/v1/auth/login',
  '/v1/auth/password_reset/tokens',
  '/v1/auth/password_reset/verify',
  '/v1/auth/password_reset/reset',
];

export const axiosConfig = {
  baseURL: process.env.BASE_URL,
  withCredentials: true,
};

export const axiosInstance = axios.create(axiosConfig);

const decrementLoadingCount = () => {
  // NOTE: 100ms以内にstartされた場合はプログレスが出続けるよう、デクリメントのタイミングを遅らせる
  setTimeout(() => mutateLoadingCount((count) => Math.max(count - 1, 0)), 100);
};

axiosInstance.interceptors.request.use(async (config) => {
  if (config.disableLoading !== true)
    await mutateLoadingCount((count) => count + 1);
  if (config.url != null && PUBLIC_APIS.includes(config.url)) return config;

  try {
    const token = await getToken(config);
    if (config.headers != null)
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  } catch (error) {
    if (config.disableLoading !== true) decrementLoadingCount();
    throw error;
  }
});

axiosInstance.interceptors.response.use(
  (config) => {
    if (config.config.disableLoading !== true) decrementLoadingCount();
    return config;
  },
  (error) => {
    if (isAxiosErrorExactly(error)) {
      if (error.config?.disableLoading !== true) decrementLoadingCount();

      // NOTE: AxiosErrorの場合のみSentryに通知
      // statusがundefinedの場合(Network Error, Abort)は通知しない
      if (error.response != null && !isIgnoreHttpException(error)) {
        Sentry.captureException(...captureFactory(error));
      }
    }
    // NOTE: useAspidaSWR()等でエラーレスポンスを受け取れるようにするためにはエラーは握りつぶせない。
    // このままだとSentryのエラーイベントが２件重複するためAxiosErrorはsentry.client.config.jsでレポートしないようにする
    return Promise.reject(error);
  }
);

export const api = $api(aspida(axiosInstance));
