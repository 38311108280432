import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_1klt739 } from './v1/auth/apply';
import type { Methods as Methods_um4um8 } from './v1/auth/login';
import type { Methods as Methods_25v4qt } from './v1/auth/logout';
import type { Methods as Methods_dx6sje } from './v1/auth/password_reset/reset';
import type { Methods as Methods_1r9de75 } from './v1/auth/password_reset/tokens';
import type { Methods as Methods_mjdtyu } from './v1/auth/password_reset/verify';
import type { Methods as Methods_vb0q0m } from './v1/auth/refresh';
import type { Methods as Methods_zc266e } from './v1/auth/register';
import type { Methods as Methods_gdfxi6 } from './v1/auth/verify';
import type { Methods as Methods_1gj9em6 } from './v1/consultants';
import type { Methods as Methods_6mpul9 } from './v1/consultants/me';
import type { Methods as Methods_10rogv7 } from './v1/consultants/me/icon';
import type { Methods as Methods_ofcazv } from './v1/consultants/me/password';
import type { Methods as Methods_njaohv } from './v1/consultants/me/school_building';
import type { Methods as Methods_15zn293 } from './v1/course_type_ids';
import type { Methods as Methods_1i56uqi } from './v1/data/account_refresh/sync';
import type { Methods as Methods_1gc7y2b } from './v1/data/shadowing_lock/sync';
import type { Methods as Methods_6b6cwx } from './v1/data/study_master/sync';
import type { Methods as Methods_ykaysw } from './v1/incharge_students';
import type { Methods as Methods_18g2bau } from './v1/school_buildings';
import type { Methods as Methods_ttgc1z } from './v1/shadowing_corrections/_shadowing_correction_id@number/kanafuri_set';
import type { Methods as Methods_ctmvbl } from './v1/shadowing_corrections_sentence_endings';
import type { Methods as Methods_10phg76 } from './v1/shadowing_items/_shadowing_item_id@number/audio_file';
import type { Methods as Methods_sbj4kw } from './v1/students';
import type { Methods as Methods_wat3qk } from './v1/students/_student_id@string';
import type { Methods as Methods_1qfr4c5 } from './v1/students/_student_id@string/courses';
import type { Methods as Methods_1wn70f } from './v1/students/_student_id@string/courses/_student_course_id@string';
import type { Methods as Methods_1y6gukq } from './v1/students/_student_id@string/courses/_student_course_id@string/update_program_start_date';
import type { Methods as Methods_1ef3krd } from './v1/students/_student_id@string/courses/_student_course_id@string/update_program_start_date_confirm';
import type { Methods as Methods_14zy6fl } from './v1/students/_student_id@string/schedules/_schedule_id@number/other_exam_records';
import type { Methods as Methods_ny9rc4 } from './v1/students/_student_id@string/schedules/_schedule_id@number/other_exam_records/_other_exam_record_id@number';
import type { Methods as Methods_luy8m7 } from './v1/students/_student_id@string/schedules/_schedule_id@number/trainings';
import type { Methods as Methods_ccqmra } from './v1/students/_student_id@string/schedules/_schedule_id@number/trainings/_training_id@number';
import type { Methods as Methods_e38pwi } from './v1/students/_student_id@string/schedules/_schedule_id@number/versant_exam_records';
import type { Methods as Methods_1qjq6bo } from './v1/students/_student_id@string/schedules/_schedule_id@number/versant_exam_records/_versant_exam_record_id@number';
import type { Methods as Methods_1yz5h6 } from './v1/students/_student_id@string/schedules/_schedule_id@number/weekly_interview_survey_answers';
import type { Methods as Methods_1j3zplw } from './v1/students/_student_id@string/schedules/_schedule_id@number/weekly_interview_survey_questions';
import type { Methods as Methods_1hyok68 } from './v1/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/trainings';
import type { Methods as Methods_p6dw79 } from './v1/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/trainings/_training_id@number';
import type { Methods as Methods_1nwuvb6 } from './v1/study_items';
import type { Methods as Methods_1mrset4 } from './v1/study_materials';
import type { Methods as Methods_155drpa } from './v1/study_menus';
import type { Methods as Methods_1gs82eu } from './v2/schedules/_schedule_id@number/info';
import type { Methods as Methods_jyeupg } from './v2/shadowing/patrol_output';
import type { Methods as Methods_j8byqu } from './v2/shadowing/students';
import type { Methods as Methods_15c3h3y } from './v2/shadowing/students/_progrit_student_id@number';
import type { Methods as Methods_1syjv4t } from './v2/shadowing/students/_progrit_student_id@number/communication';
import type { Methods as Methods_dn4xlk } from './v2/shadowing/students/_progrit_student_id@number/statistics';
import type { Methods as Methods_176hooh } from './v2/shadowing_corrections/_shadowing_correction_id@number';
import type { Methods as Methods_1trugvk } from './v2/shadowing_corrections/_shadowing_correction_id@number/kanafuri_set';
import type { Methods as Methods_6oukr9 } from './v2/shadowing_notes/_shadowing_note_id@number';
import type { Methods as Methods_1vtrw5w } from './v2/shadowing_recordings/_student_shadowing_recording_id@number/file';
import type { Methods as Methods_ljvicw } from './v2/students/_progrit_student_id@number/shadowing_corrections';
import type { Methods as Methods_1jfcvc } from './v2/students/_progrit_student_id@number/shadowing_notes';
import type { Methods as Methods_1dw8zrq } from './v2/students/_student_id@string/schedules/_schedule_id@number/entire_schedule';
import type { Methods as Methods_1p4zetr } from './v2/students/_student_id@string/schedules/_schedule_id@number/study_menus';
import type { Methods as Methods_1mflgbt } from './v2/students/_student_id@string/schedules/_schedule_id@number/study_menus/_schedule_study_menu_id@number';
import type { Methods as Methods_2qgt5d } from './v2/students/_student_id@string/schedules/_schedule_id@number/weekly_interviews';
import type { Methods as Methods_abdieh } from './v2/students/_student_id@string/schedules/_schedule_id@number/weekly_study_status';
import type { Methods as Methods_o2vuco } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks';
import type { Methods as Methods_ycf00z } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number';
import type { Methods as Methods_jks3qn } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/interview';
import type { Methods as Methods_aszbus } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/study_status';
import type { Methods as Methods_1qbyfo } from './v2/students/_student_id@string/schedules/_schedule_id@number/wpm_transitions';
import type { Methods as Methods_rcwznd } from './v2/students/_student_id@string/student_courses/_student_course_id@string/versant_exam_records';
import type { Methods as Methods_1dqp7q1 } from './v2/students/_student_id@string/student_courses/_student_course_id@string/versant_exam_records/_versant_exam_record_id@number';
import type { Methods as Methods_18h6a4n } from './v3/data/consultants/sync';
import type { Methods as Methods_t1ouer } from './v3/schedules/_schedule_id@number/conversation_study_records';
import type { Methods as Methods_176o0je } from './v3/schedules/_schedule_id@number/entire_schedule';
import type { Methods as Methods_b3m4n3 } from './v3/schedules/_schedule_id@number/exam_study_records';
import type { Methods as Methods_1pknytc } from './v3/schedules/_schedule_id@number/extensive_reading_study_records';
import type { Methods as Methods_1o6odmr } from './v3/schedules/_schedule_id@number/grammar_study_records';
import type { Methods as Methods_15e7g5m } from './v3/schedules/_schedule_id@number/intensive_reading_study_records';
import type { Methods as Methods_xhitra } from './v3/schedules/_schedule_id@number/speed_reading_study_records';
import type { Methods as Methods_vbvamz } from './v3/trainings/_training_id@number/has_plan';
import type { Methods as Methods_dhd5yl } from './v4/schedules/_schedule_id@number/extensive_reading_study_records';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (
    baseURL === undefined ? 'https://api-dev.aesop.progrit.work' : baseURL
  ).replace(/\/$/, '');
  const PATH0 = '/v1/auth/apply';
  const PATH1 = '/v1/auth/login';
  const PATH2 = '/v1/auth/logout';
  const PATH3 = '/v1/auth/password_reset/reset';
  const PATH4 = '/v1/auth/password_reset/tokens';
  const PATH5 = '/v1/auth/password_reset/verify';
  const PATH6 = '/v1/auth/refresh';
  const PATH7 = '/v1/auth/register';
  const PATH8 = '/v1/auth/verify';
  const PATH9 = '/v1/consultants';
  const PATH10 = '/v1/consultants/me';
  const PATH11 = '/v1/consultants/me/icon';
  const PATH12 = '/v1/consultants/me/password';
  const PATH13 = '/v1/consultants/me/school_building';
  const PATH14 = '/v1/course_type_ids';
  const PATH15 = '/v1/data/account_refresh/sync';
  const PATH16 = '/v1/data/shadowing_lock/sync';
  const PATH17 = '/v1/data/study_master/sync';
  const PATH18 = '/v1/incharge_students';
  const PATH19 = '/v1/school_buildings';
  const PATH20 = '/v1/shadowing_corrections';
  const PATH21 = '/kanafuri_set';
  const PATH22 = '/v1/shadowing_corrections_sentence_endings';
  const PATH23 = '/v1/shadowing_items';
  const PATH24 = '/audio_file';
  const PATH25 = '/v1/students';
  const PATH26 = '/courses';
  const PATH27 = '/update_program_start_date';
  const PATH28 = '/update_program_start_date_confirm';
  const PATH29 = '/schedules';
  const PATH30 = '/other_exam_records';
  const PATH31 = '/trainings';
  const PATH32 = '/versant_exam_records';
  const PATH33 = '/weekly_interview_survey_answers';
  const PATH34 = '/weekly_interview_survey_questions';
  const PATH35 = '/weeks';
  const PATH36 = '/v1/study_items';
  const PATH37 = '/v1/study_materials';
  const PATH38 = '/v1/study_menus';
  const PATH39 = '/v2/schedules';
  const PATH40 = '/info';
  const PATH41 = '/v2/shadowing/patrol_output';
  const PATH42 = '/v2/shadowing/students';
  const PATH43 = '/communication';
  const PATH44 = '/statistics';
  const PATH45 = '/v2/shadowing_corrections';
  const PATH46 = '/v2/shadowing_notes';
  const PATH47 = '/v2/shadowing_recordings';
  const PATH48 = '/file';
  const PATH49 = '/v2/students';
  const PATH50 = '/shadowing_corrections';
  const PATH51 = '/shadowing_notes';
  const PATH52 = '/entire_schedule';
  const PATH53 = '/study_menus';
  const PATH54 = '/weekly_interviews';
  const PATH55 = '/weekly_study_status';
  const PATH56 = '/interview';
  const PATH57 = '/study_status';
  const PATH58 = '/wpm_transitions';
  const PATH59 = '/student_courses';
  const PATH60 = '/v3/data/consultants/sync';
  const PATH61 = '/v3/schedules';
  const PATH62 = '/conversation_study_records';
  const PATH63 = '/exam_study_records';
  const PATH64 = '/extensive_reading_study_records';
  const PATH65 = '/grammar_study_records';
  const PATH66 = '/intensive_reading_study_records';
  const PATH67 = '/speed_reading_study_records';
  const PATH68 = '/v3/trainings';
  const PATH69 = '/has_plan';
  const PATH70 = '/v4/schedules';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';

  return {
    v1: {
      auth: {
        apply: {
          /**
           * @returns 成功し、パスワード初期設定メールを送信する
           */
          post: (option: {
            body: Methods_1klt739['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1klt739['post']['resBody'],
              BasicHeaders,
              Methods_1klt739['post']['status']
            >(prefix, PATH0, POST, option).json(),
          /**
           * @returns 成功し、パスワード初期設定メールを送信する
           */
          $post: (option: {
            body: Methods_1klt739['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1klt739['post']['resBody'],
              BasicHeaders,
              Methods_1klt739['post']['status']
            >(prefix, PATH0, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH0}`,
        },
        login: {
          /**
           * @returns トークンを返す
           */
          post: (option: {
            body: Methods_um4um8['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_um4um8['post']['resBody'],
              BasicHeaders,
              Methods_um4um8['post']['status']
            >(prefix, PATH1, POST, option).json(),
          /**
           * @returns トークンを返す
           */
          $post: (option: {
            body: Methods_um4um8['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_um4um8['post']['resBody'],
              BasicHeaders,
              Methods_um4um8['post']['status']
            >(prefix, PATH1, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH1}`,
        },
        logout: {
          /**
           * @returns ログアウト成功
           */
          post: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_25v4qt['post']['resBody'],
              BasicHeaders,
              Methods_25v4qt['post']['status']
            >(prefix, PATH2, POST, option).json(),
          /**
           * @returns ログアウト成功
           */
          $post: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_25v4qt['post']['resBody'],
              BasicHeaders,
              Methods_25v4qt['post']['status']
            >(prefix, PATH2, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH2}`,
        },
        password_reset: {
          reset: {
            /**
             * パスワードリセットを行う。
             * @returns 成功
             */
            post: (option: {
              body: Methods_dx6sje['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_dx6sje['post']['resBody'],
                BasicHeaders,
                Methods_dx6sje['post']['status']
              >(prefix, PATH3, POST, option).json(),
            /**
             * パスワードリセットを行う。
             * @returns 成功
             */
            $post: (option: {
              body: Methods_dx6sje['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_dx6sje['post']['resBody'],
                BasicHeaders,
                Methods_dx6sje['post']['status']
              >(prefix, PATH3, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH3}`,
          },
          tokens: {
            /**
             * パスワードリセットトークンを発行し、メールで送信する。
             * @returns 成功
             */
            post: (option: {
              body: Methods_1r9de75['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1r9de75['post']['resBody'],
                BasicHeaders,
                Methods_1r9de75['post']['status']
              >(prefix, PATH4, POST, option).json(),
            /**
             * パスワードリセットトークンを発行し、メールで送信する。
             * @returns 成功
             */
            $post: (option: {
              body: Methods_1r9de75['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1r9de75['post']['resBody'],
                BasicHeaders,
                Methods_1r9de75['post']['status']
              >(prefix, PATH4, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH4}`,
          },
          verify: {
            /**
             * パスワードリセットトークンの有効性を確認する。
             *
             * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
             *
             * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
             * @returns 成功
             */
            get: (option: {
              query: Methods_mjdtyu['get']['query'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_mjdtyu['get']['resBody'],
                BasicHeaders,
                Methods_mjdtyu['get']['status']
              >(prefix, PATH5, GET, option).json(),
            /**
             * パスワードリセットトークンの有効性を確認する。
             *
             * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
             *
             * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
             * @returns 成功
             */
            $get: (option: {
              query: Methods_mjdtyu['get']['query'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_mjdtyu['get']['resBody'],
                BasicHeaders,
                Methods_mjdtyu['get']['status']
              >(prefix, PATH5, GET, option)
                .json()
                .then((r) => r.body),
            $path: (
              option?:
                | {
                    method?: 'get' | undefined;
                    query: Methods_mjdtyu['get']['query'];
                  }
                | undefined
            ) =>
              `${prefix}${PATH5}${
                option && option.query
                  ? `?${dataToURLString(option.query)}`
                  : ''
              }`,
          },
        },
        refresh: {
          /**
           * @returns 期間延長されたトークンを返却する
           */
          post: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_vb0q0m['post']['resBody'],
              BasicHeaders,
              Methods_vb0q0m['post']['status']
            >(prefix, PATH6, POST, option).json(),
          /**
           * @returns 期間延長されたトークンを返却する
           */
          $post: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_vb0q0m['post']['resBody'],
              BasicHeaders,
              Methods_vb0q0m['post']['status']
            >(prefix, PATH6, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH6}`,
        },
        register: {
          /**
           * @returns パスワードを設定しログイン、トークンを返す
           */
          post: (option: {
            body: Methods_zc266e['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_zc266e['post']['resBody'],
              BasicHeaders,
              Methods_zc266e['post']['status']
            >(prefix, PATH7, POST, option).json(),
          /**
           * @returns パスワードを設定しログイン、トークンを返す
           */
          $post: (option: {
            body: Methods_zc266e['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_zc266e['post']['resBody'],
              BasicHeaders,
              Methods_zc266e['post']['status']
            >(prefix, PATH7, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH7}`,
        },
        verify: {
          /**
           * 初期パスワード登録用トークンの有効性を確認する。
           *
           * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
           *
           * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
           * @returns 成功
           */
          get: (option: {
            query: Methods_gdfxi6['get']['query'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_gdfxi6['get']['resBody'],
              BasicHeaders,
              Methods_gdfxi6['get']['status']
            >(prefix, PATH8, GET, option).json(),
          /**
           * 初期パスワード登録用トークンの有効性を確認する。
           *
           * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
           *
           * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
           * @returns 成功
           */
          $get: (option: {
            query: Methods_gdfxi6['get']['query'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_gdfxi6['get']['resBody'],
              BasicHeaders,
              Methods_gdfxi6['get']['status']
            >(prefix, PATH8, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: 'get' | undefined;
                  query: Methods_gdfxi6['get']['query'];
                }
              | undefined
          ) =>
            `${prefix}${PATH8}${
              option && option.query ? `?${dataToURLString(option.query)}` : ''
            }`,
        },
      },
      consultants: {
        me: {
          icon: {
            /**
             * アイコン画像を更新する。
             *
             * ### 画像ファイルの条件
             * - 画像種別: jpg, png, gif
             * - 最大ファイルサイズ: 5MB
             * @returns 成功
             */
            post: (option: {
              body: Methods_10rogv7['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_10rogv7['post']['resBody'],
                BasicHeaders,
                Methods_10rogv7['post']['status']
              >(prefix, PATH11, POST, option, 'FormData').json(),
            /**
             * アイコン画像を更新する。
             *
             * ### 画像ファイルの条件
             * - 画像種別: jpg, png, gif
             * - 最大ファイルサイズ: 5MB
             * @returns 成功
             */
            $post: (option: {
              body: Methods_10rogv7['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_10rogv7['post']['resBody'],
                BasicHeaders,
                Methods_10rogv7['post']['status']
              >(prefix, PATH11, POST, option, 'FormData')
                .json()
                .then((r) => r.body),
            /**
             * アイコン画像を削除する。
             * @returns 成功
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_10rogv7['delete']['resBody'],
                BasicHeaders,
                Methods_10rogv7['delete']['status']
              >(prefix, PATH11, DELETE, option).json(),
            /**
             * アイコン画像を削除する。
             * @returns 成功
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_10rogv7['delete']['resBody'],
                BasicHeaders,
                Methods_10rogv7['delete']['status']
              >(prefix, PATH11, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH11}`,
          },
          password: {
            /**
             * パスワードを変更する。
             * @returns 成功
             */
            put: (option: {
              body: Methods_ofcazv['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_ofcazv['put']['resBody'],
                BasicHeaders,
                Methods_ofcazv['put']['status']
              >(prefix, PATH12, PUT, option).json(),
            /**
             * パスワードを変更する。
             * @returns 成功
             */
            $put: (option: {
              body: Methods_ofcazv['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_ofcazv['put']['resBody'],
                BasicHeaders,
                Methods_ofcazv['put']['status']
              >(prefix, PATH12, PUT, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH12}`,
          },
          school_building: {
            /**
             * 所属校舎を変更する
             *
             * 存在しない後者の場合はステータス:422を返す。
             * @returns 成功
             */
            put: (option: {
              body: Methods_njaohv['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_njaohv['put']['resBody'],
                BasicHeaders,
                Methods_njaohv['put']['status']
              >(prefix, PATH13, PUT, option).json(),
            /**
             * 所属校舎を変更する
             *
             * 存在しない後者の場合はステータス:422を返す。
             * @returns 成功
             */
            $put: (option: {
              body: Methods_njaohv['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_njaohv['put']['resBody'],
                BasicHeaders,
                Methods_njaohv['put']['status']
              >(prefix, PATH13, PUT, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH13}`,
          },
          /**
           * ログイン中のコンサルタントを取得する。
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_6mpul9['get']['resBody'],
              BasicHeaders,
              Methods_6mpul9['get']['status']
            >(prefix, PATH10, GET, option).json(),
          /**
           * ログイン中のコンサルタントを取得する。
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_6mpul9['get']['resBody'],
              BasicHeaders,
              Methods_6mpul9['get']['status']
            >(prefix, PATH10, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH10}`,
        },
        /**
         * コンサルタント一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1gj9em6['get']['resBody'],
            BasicHeaders,
            Methods_1gj9em6['get']['status']
          >(prefix, PATH9, GET, option).json(),
        /**
         * コンサルタント一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1gj9em6['get']['resBody'],
            BasicHeaders,
            Methods_1gj9em6['get']['status']
          >(prefix, PATH9, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH9}`,
      },
      course_type_ids: {
        /**
         * コース検索条件一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_15zn293['get']['resBody'],
            BasicHeaders,
            Methods_15zn293['get']['status']
          >(prefix, PATH14, GET, option).json(),
        /**
         * コース検索条件一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_15zn293['get']['resBody'],
            BasicHeaders,
            Methods_15zn293['get']['status']
          >(prefix, PATH14, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH14}`,
      },
      data: {
        account_refresh: {
          sync: {
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1WLIq7iIKvYoxQZKfFkKuPRIY3CrF8U3TMrA-zWbtOjA/edit#gid=523006916)
             * @returns 成功
             */
            post: (option: {
              body: Methods_1i56uqi['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1i56uqi['post']['resBody'],
                BasicHeaders,
                Methods_1i56uqi['post']['status']
              >(prefix, PATH15, POST, option).json(),
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1WLIq7iIKvYoxQZKfFkKuPRIY3CrF8U3TMrA-zWbtOjA/edit#gid=523006916)
             * @returns 成功
             */
            $post: (option: {
              body: Methods_1i56uqi['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1i56uqi['post']['resBody'],
                BasicHeaders,
                Methods_1i56uqi['post']['status']
              >(prefix, PATH15, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH15}`,
          },
        },
        shadowing_lock: {
          sync: {
            /**
             * シャドーイング添削をロックするAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1kAN6uTkIaZ1IOvP4xR5gRowP88_T16ASGtoYdqqQsNQ/edit#gid=1308064237)
             * @returns 成功
             */
            post: (option: {
              body: Methods_1gc7y2b['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1gc7y2b['post']['resBody'],
                BasicHeaders,
                Methods_1gc7y2b['post']['status']
              >(prefix, PATH16, POST, option).json(),
            /**
             * シャドーイング添削をロックするAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1kAN6uTkIaZ1IOvP4xR5gRowP88_T16ASGtoYdqqQsNQ/edit#gid=1308064237)
             * @returns 成功
             */
            $post: (option: {
              body: Methods_1gc7y2b['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1gc7y2b['post']['resBody'],
                BasicHeaders,
                Methods_1gc7y2b['post']['status']
              >(prefix, PATH16, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH16}`,
          },
        },
        study_master: {
          sync: {
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1fXBdtkNPycRt8Vi8A0iCx_27StMzWUwkqND7lOtK1Vo/edit#gid=778628051)
             * @returns 成功
             */
            post: (option: {
              body: Methods_6b6cwx['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_6b6cwx['post']['resBody'],
                BasicHeaders,
                Methods_6b6cwx['post']['status']
              >(prefix, PATH17, POST, option).json(),
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1fXBdtkNPycRt8Vi8A0iCx_27StMzWUwkqND7lOtK1Vo/edit#gid=778628051)
             * @returns 成功
             */
            $post: (option: {
              body: Methods_6b6cwx['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_6b6cwx['post']['resBody'],
                BasicHeaders,
                Methods_6b6cwx['post']['status']
              >(prefix, PATH17, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH17}`,
          },
        },
      },
      incharge_students: {
        /**
         * プログラム期間中の生徒一覧を条件を指定して取得する
         * @returns 成功
         */
        get: (
          option?:
            | {
                query?: Methods_ykaysw['get']['query'] | undefined;
                config?: T | undefined;
              }
            | undefined
        ) =>
          fetch<
            Methods_ykaysw['get']['resBody'],
            BasicHeaders,
            Methods_ykaysw['get']['status']
          >(prefix, PATH18, GET, option).json(),
        /**
         * プログラム期間中の生徒一覧を条件を指定して取得する
         * @returns 成功
         */
        $get: (
          option?:
            | {
                query?: Methods_ykaysw['get']['query'] | undefined;
                config?: T | undefined;
              }
            | undefined
        ) =>
          fetch<
            Methods_ykaysw['get']['resBody'],
            BasicHeaders,
            Methods_ykaysw['get']['status']
          >(prefix, PATH18, GET, option)
            .json()
            .then((r) => r.body),
        $path: (
          option?:
            | {
                method?: 'get' | undefined;
                query: Methods_ykaysw['get']['query'];
              }
            | undefined
        ) =>
          `${prefix}${PATH18}${
            option && option.query ? `?${dataToURLString(option.query)}` : ''
          }`,
      },
      school_buildings: {
        /**
         * 校舎一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_18g2bau['get']['resBody'],
            BasicHeaders,
            Methods_18g2bau['get']['status']
          >(prefix, PATH19, GET, option).json(),
        /**
         * 校舎一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_18g2bau['get']['resBody'],
            BasicHeaders,
            Methods_18g2bau['get']['status']
          >(prefix, PATH19, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH19}`,
      },
      shadowing_corrections: {
        _shadowing_correction_id: (val2: number) => {
          const prefix2 = `${PATH20}/${val2}`;

          return {
            kanafuri_set: {
              /**
               * シャドーイング添削に紐づくカナフリセットを取得する。
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_ttgc1z['get']['resBody'],
                  BasicHeaders,
                  Methods_ttgc1z['get']['status']
                >(prefix, `${prefix2}${PATH21}`, GET, option).json(),
              /**
               * シャドーイング添削に紐づくカナフリセットを取得する。
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_ttgc1z['get']['resBody'],
                  BasicHeaders,
                  Methods_ttgc1z['get']['status']
                >(prefix, `${prefix2}${PATH21}`, GET, option)
                  .json()
                  .then((r) => r.body),
              /**
               * シャドーイング添削に紐づくカナフリセットを更新する。
               * @returns 成功
               */
              put: (option: {
                body: Methods_ttgc1z['put']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_ttgc1z['put']['resBody'],
                  BasicHeaders,
                  Methods_ttgc1z['put']['status']
                >(prefix, `${prefix2}${PATH21}`, PUT, option).json(),
              /**
               * シャドーイング添削に紐づくカナフリセットを更新する。
               * @returns 成功
               */
              $put: (option: {
                body: Methods_ttgc1z['put']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_ttgc1z['put']['resBody'],
                  BasicHeaders,
                  Methods_ttgc1z['put']['status']
                >(prefix, `${prefix2}${PATH21}`, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH21}`,
            },
          };
        },
      },
      shadowing_corrections_sentence_endings: {
        /**
         * 添削コメント語尾マスタ一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_ctmvbl['get']['resBody'],
            BasicHeaders,
            Methods_ctmvbl['get']['status']
          >(prefix, PATH22, GET, option).json(),
        /**
         * 添削コメント語尾マスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_ctmvbl['get']['resBody'],
            BasicHeaders,
            Methods_ctmvbl['get']['status']
          >(prefix, PATH22, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH22}`,
      },
      shadowing_items: {
        _shadowing_item_id: (val2: number) => {
          const prefix2 = `${PATH23}/${val2}`;

          return {
            audio_file: {
              /**
               * シャドーイング音源ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_10phg76['get']['resBody'],
                  BasicHeaders,
                  Methods_10phg76['get']['status']
                >(prefix, `${prefix2}${PATH24}`, GET, option).blob(),
              /**
               * シャドーイング音源ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_10phg76['get']['resBody'],
                  BasicHeaders,
                  Methods_10phg76['get']['status']
                >(prefix, `${prefix2}${PATH24}`, GET, option)
                  .blob()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`,
            },
          };
        },
      },
      students: {
        _student_id: (val2: string) => {
          const prefix2 = `${PATH25}/${val2}`;

          return {
            courses: {
              _student_course_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH26}/${val4}`;

                return {
                  update_program_start_date: {
                    /**
                     * 受講コースの開始日を登録/更新する。
                     *
                     * - 登録の場合
                     *   - 全体スケジュールの週、目的の初期設定を登録する。
                     * - 更新の場合
                     *   - 全体スケジュールの週設定の開始日、終了日を更新する。
                     *   - 学習予定、学習記録が存在する場合、削除する。
                     * @returns 成功
                     */
                    post: (option: {
                      body: Methods_1y6gukq['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_1y6gukq['post']['resBody'],
                        BasicHeaders,
                        Methods_1y6gukq['post']['status']
                      >(prefix, `${prefix4}${PATH27}`, POST, option).json(),
                    /**
                     * 受講コースの開始日を登録/更新する。
                     *
                     * - 登録の場合
                     *   - 全体スケジュールの週、目的の初期設定を登録する。
                     * - 更新の場合
                     *   - 全体スケジュールの週設定の開始日、終了日を更新する。
                     *   - 学習予定、学習記録が存在する場合、削除する。
                     * @returns 成功
                     */
                    $post: (option: {
                      body: Methods_1y6gukq['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_1y6gukq['post']['resBody'],
                        BasicHeaders,
                        Methods_1y6gukq['post']['status']
                      >(prefix, `${prefix4}${PATH27}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH27}`,
                  },
                  update_program_start_date_confirm: {
                    /**
                     * 受講コース開始日の更新確認を行う。
                     *
                     * 学習予定または学習記録が存在する場合、`exist_data_flg`で`true`を返す。
                     * @returns 成功
                     */
                    post: (option: {
                      body: Methods_1ef3krd['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_1ef3krd['post']['resBody'],
                        BasicHeaders,
                        Methods_1ef3krd['post']['status']
                      >(prefix, `${prefix4}${PATH28}`, POST, option).json(),
                    /**
                     * 受講コース開始日の更新確認を行う。
                     *
                     * 学習予定または学習記録が存在する場合、`exist_data_flg`で`true`を返す。
                     * @returns 成功
                     */
                    $post: (option: {
                      body: Methods_1ef3krd['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_1ef3krd['post']['resBody'],
                        BasicHeaders,
                        Methods_1ef3krd['post']['status']
                      >(prefix, `${prefix4}${PATH28}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH28}`,
                  },
                  /**
                   * 受講コースを更新する。
                   *
                   * - active_flgはtrueのみ指定可能。trueで指定されたコース以外はfalseで更新される。
                   * - 受講コース開始日は別API (受講コース開始日の登録/更新API) にて行う。
                   * @returns 成功
                   */
                  put: (option: {
                    body: Methods_1wn70f['put']['reqBody'];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_1wn70f['put']['resBody'],
                      BasicHeaders,
                      Methods_1wn70f['put']['status']
                    >(prefix, prefix4, PUT, option).json(),
                  /**
                   * 受講コースを更新する。
                   *
                   * - active_flgはtrueのみ指定可能。trueで指定されたコース以外はfalseで更新される。
                   * - 受講コース開始日は別API (受講コース開始日の登録/更新API) にて行う。
                   * @returns 成功
                   */
                  $put: (option: {
                    body: Methods_1wn70f['put']['reqBody'];
                    config?: T | undefined;
                  }) =>
                    fetch<
                      Methods_1wn70f['put']['resBody'],
                      BasicHeaders,
                      Methods_1wn70f['put']['status']
                    >(prefix, prefix4, PUT, option)
                      .json()
                      .then((r) => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * 受講コース一覧を取得する。
               *
               * 一覧は開始日, id順でソートされる
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1qfr4c5['get']['resBody'],
                  BasicHeaders,
                  Methods_1qfr4c5['get']['status']
                >(prefix, `${prefix2}${PATH26}`, GET, option).json(),
              /**
               * 受講コース一覧を取得する。
               *
               * 一覧は開始日, id順でソートされる
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1qfr4c5['get']['resBody'],
                  BasicHeaders,
                  Methods_1qfr4c5['get']['status']
                >(prefix, `${prefix2}${PATH26}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH26}`,
            },
            schedules: {
              _schedule_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH29}/${val4}`;

                return {
                  other_exam_records: {
                    _other_exam_record_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH30}/${val6}`;

                      return {
                        /**
                         * その他テスト記録を更新する。
                         * @returns 成功
                         */
                        put: (option: {
                          body: Methods_ny9rc4['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_ny9rc4['put']['resBody'],
                            BasicHeaders,
                            Methods_ny9rc4['put']['status']
                          >(prefix, prefix6, PUT, option).json(),
                        /**
                         * その他テスト記録を更新する。
                         * @returns 成功
                         */
                        $put: (option: {
                          body: Methods_ny9rc4['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_ny9rc4['put']['resBody'],
                            BasicHeaders,
                            Methods_ny9rc4['put']['status']
                          >(prefix, prefix6, PUT, option)
                            .json()
                            .then((r) => r.body),
                        /**
                         * その他テスト記録を削除する。
                         * @returns 成功
                         */
                        delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ny9rc4['delete']['resBody'],
                            BasicHeaders,
                            Methods_ny9rc4['delete']['status']
                          >(prefix, prefix6, DELETE, option).json(),
                        /**
                         * その他テスト記録を削除する。
                         * @returns 成功
                         */
                        $delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ny9rc4['delete']['resBody'],
                            BasicHeaders,
                            Methods_ny9rc4['delete']['status']
                          >(prefix, prefix6, DELETE, option)
                            .json()
                            .then((r) => r.body),
                        $path: () => `${prefix}${prefix6}`,
                      };
                    },
                    /**
                     * その他テスト記録一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_14zy6fl['get']['resBody'],
                        BasicHeaders,
                        Methods_14zy6fl['get']['status']
                      >(prefix, `${prefix4}${PATH30}`, GET, option).json(),
                    /**
                     * その他テスト記録一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_14zy6fl['get']['resBody'],
                        BasicHeaders,
                        Methods_14zy6fl['get']['status']
                      >(prefix, `${prefix4}${PATH30}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    /**
                     * その他テスト記録を登録する。
                     * @returns 成功
                     */
                    post: (option: {
                      body: Methods_14zy6fl['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_14zy6fl['post']['resBody'],
                        BasicHeaders,
                        Methods_14zy6fl['post']['status']
                      >(prefix, `${prefix4}${PATH30}`, POST, option).json(),
                    /**
                     * その他テスト記録を登録する。
                     * @returns 成功
                     */
                    $post: (option: {
                      body: Methods_14zy6fl['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_14zy6fl['post']['resBody'],
                        BasicHeaders,
                        Methods_14zy6fl['post']['status']
                      >(prefix, `${prefix4}${PATH30}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH30}`,
                  },
                  trainings: {
                    _training_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH31}/${val6}`;

                      return {
                        /**
                         * トレーニングを取得する。
                         * @returns 成功
                         */
                        get: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ccqmra['get']['resBody'],
                            BasicHeaders,
                            Methods_ccqmra['get']['status']
                          >(prefix, prefix6, GET, option).json(),
                        /**
                         * トレーニングを取得する。
                         * @returns 成功
                         */
                        $get: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ccqmra['get']['resBody'],
                            BasicHeaders,
                            Methods_ccqmra['get']['status']
                          >(prefix, prefix6, GET, option)
                            .json()
                            .then((r) => r.body),
                        /**
                         * トレーニングを更新する。
                         *
                         * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                         * @returns 成功
                         */
                        put: (option: {
                          body: Methods_ccqmra['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_ccqmra['put']['resBody'],
                            BasicHeaders,
                            Methods_ccqmra['put']['status']
                          >(prefix, prefix6, PUT, option).json(),
                        /**
                         * トレーニングを更新する。
                         *
                         * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                         * @returns 成功
                         */
                        $put: (option: {
                          body: Methods_ccqmra['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_ccqmra['put']['resBody'],
                            BasicHeaders,
                            Methods_ccqmra['put']['status']
                          >(prefix, prefix6, PUT, option)
                            .json()
                            .then((r) => r.body),
                        /**
                         * トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - トレーニングに紐づく学習予定が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ccqmra['delete']['resBody'],
                            BasicHeaders,
                            Methods_ccqmra['delete']['status']
                          >(prefix, prefix6, DELETE, option).json(),
                        /**
                         * トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - トレーニングに紐づく学習予定が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        $delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ccqmra['delete']['resBody'],
                            BasicHeaders,
                            Methods_ccqmra['delete']['status']
                          >(prefix, prefix6, DELETE, option)
                            .json()
                            .then((r) => r.body),
                        $path: () => `${prefix}${prefix6}`,
                      };
                    },
                    /**
                     * トレーニングを登録する。
                     *
                     * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                     * @returns 成功
                     */
                    post: (option: {
                      body: Methods_luy8m7['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_luy8m7['post']['resBody'],
                        BasicHeaders,
                        Methods_luy8m7['post']['status']
                      >(prefix, `${prefix4}${PATH31}`, POST, option).json(),
                    /**
                     * トレーニングを登録する。
                     *
                     * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                     * @returns 成功
                     */
                    $post: (option: {
                      body: Methods_luy8m7['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_luy8m7['post']['resBody'],
                        BasicHeaders,
                        Methods_luy8m7['post']['status']
                      >(prefix, `${prefix4}${PATH31}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH31}`,
                  },
                  versant_exam_records: {
                    _versant_exam_record_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH32}/${val6}`;

                      return {
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        put: (option: {
                          body: Methods_1qjq6bo['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_1qjq6bo['put']['resBody'],
                            BasicHeaders,
                            Methods_1qjq6bo['put']['status']
                          >(prefix, prefix6, PUT, option).json(),
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        $put: (option: {
                          body: Methods_1qjq6bo['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_1qjq6bo['put']['resBody'],
                            BasicHeaders,
                            Methods_1qjq6bo['put']['status']
                          >(prefix, prefix6, PUT, option)
                            .json()
                            .then((r) => r.body),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1qjq6bo['delete']['resBody'],
                            BasicHeaders,
                            Methods_1qjq6bo['delete']['status']
                          >(prefix, prefix6, DELETE, option).json(),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        $delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1qjq6bo['delete']['resBody'],
                            BasicHeaders,
                            Methods_1qjq6bo['delete']['status']
                          >(prefix, prefix6, DELETE, option)
                            .json()
                            .then((r) => r.body),
                        $path: () => `${prefix}${prefix6}`,
                      };
                    },
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_e38pwi['get']['resBody'],
                        BasicHeaders,
                        Methods_e38pwi['get']['status']
                      >(prefix, `${prefix4}${PATH32}`, GET, option).json(),
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_e38pwi['get']['resBody'],
                        BasicHeaders,
                        Methods_e38pwi['get']['status']
                      >(prefix, `${prefix4}${PATH32}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    post: (option: {
                      body: Methods_e38pwi['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_e38pwi['post']['resBody'],
                        BasicHeaders,
                        Methods_e38pwi['post']['status']
                      >(prefix, `${prefix4}${PATH32}`, POST, option).json(),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    $post: (option: {
                      body: Methods_e38pwi['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_e38pwi['post']['resBody'],
                        BasicHeaders,
                        Methods_e38pwi['post']['status']
                      >(prefix, `${prefix4}${PATH32}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH32}`,
                  },
                  weekly_interview_survey_answers: {
                    /**
                     * 週間面談 定量アンケート回答一覧を取得する。
                     *
                     * - 回答が未回答の場合、`answer: null`を返す
                     * - マスタが途中で追加された場合、項目自体が返らない
                     *
                     * - レスポンスのソート条件
                     *   - 第一条件: weekの昇順
                     *   - 第二条件: weekly_interview_survey_questions.display_order (右側の凡例の「学習時間の確保」「集中度」...と同じ順序)
                     *
                     * - [表示仕様メモ](https://github.com/progrit/consultingAppManagementFront/issues/438#issuecomment-730969991)
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1yz5h6['get']['resBody'],
                        BasicHeaders,
                        Methods_1yz5h6['get']['status']
                      >(prefix, `${prefix4}${PATH33}`, GET, option).json(),
                    /**
                     * 週間面談 定量アンケート回答一覧を取得する。
                     *
                     * - 回答が未回答の場合、`answer: null`を返す
                     * - マスタが途中で追加された場合、項目自体が返らない
                     *
                     * - レスポンスのソート条件
                     *   - 第一条件: weekの昇順
                     *   - 第二条件: weekly_interview_survey_questions.display_order (右側の凡例の「学習時間の確保」「集中度」...と同じ順序)
                     *
                     * - [表示仕様メモ](https://github.com/progrit/consultingAppManagementFront/issues/438#issuecomment-730969991)
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1yz5h6['get']['resBody'],
                        BasicHeaders,
                        Methods_1yz5h6['get']['status']
                      >(prefix, `${prefix4}${PATH33}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH33}`,
                  },
                  weekly_interview_survey_questions: {
                    /**
                     * 週間面談 定量アンケート質問一覧を取得する。
                     *   - マスタから削除された項目でも回答が存在する場合は含める
                     *   - 凡例表示で使用
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1j3zplw['get']['resBody'],
                        BasicHeaders,
                        Methods_1j3zplw['get']['status']
                      >(prefix, `${prefix4}${PATH34}`, GET, option).json(),
                    /**
                     * 週間面談 定量アンケート質問一覧を取得する。
                     *   - マスタから削除された項目でも回答が存在する場合は含める
                     *   - 凡例表示で使用
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1j3zplw['get']['resBody'],
                        BasicHeaders,
                        Methods_1j3zplw['get']['status']
                      >(prefix, `${prefix4}${PATH34}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH34}`,
                  },
                  weeks: {
                    _week: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH35}/${val6}`;

                      return {
                        trainings: {
                          _training_id: (val8: number) => {
                            const prefix8 = `${prefix6}${PATH31}/${val8}`;

                            return {
                              /**
                               * 週間トレーニングを取得する。
                               *
                               * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                               * @returns 成功
                               */
                              get: (
                                option?: { config?: T | undefined } | undefined
                              ) =>
                                fetch<
                                  Methods_p6dw79['get']['resBody'],
                                  BasicHeaders,
                                  Methods_p6dw79['get']['status']
                                >(prefix, prefix8, GET, option).json(),
                              /**
                               * 週間トレーニングを取得する。
                               *
                               * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                               * @returns 成功
                               */
                              $get: (
                                option?: { config?: T | undefined } | undefined
                              ) =>
                                fetch<
                                  Methods_p6dw79['get']['resBody'],
                                  BasicHeaders,
                                  Methods_p6dw79['get']['status']
                                >(prefix, prefix8, GET, option)
                                  .json()
                                  .then((r) => r.body),
                              /**
                               * 週間トレーニングを登録する。
                               *
                               * 指定されたtraining_id, weekのデータが既に存在する場合は更新する。
                               * @returns 成功
                               */
                              put: (option: {
                                body: Methods_p6dw79['put']['reqBody'];
                                config?: T | undefined;
                              }) =>
                                fetch<
                                  Methods_p6dw79['put']['resBody'],
                                  BasicHeaders,
                                  Methods_p6dw79['put']['status']
                                >(prefix, prefix8, PUT, option).json(),
                              /**
                               * 週間トレーニングを登録する。
                               *
                               * 指定されたtraining_id, weekのデータが既に存在する場合は更新する。
                               * @returns 成功
                               */
                              $put: (option: {
                                body: Methods_p6dw79['put']['reqBody'];
                                config?: T | undefined;
                              }) =>
                                fetch<
                                  Methods_p6dw79['put']['resBody'],
                                  BasicHeaders,
                                  Methods_p6dw79['put']['status']
                                >(prefix, prefix8, PUT, option)
                                  .json()
                                  .then((r) => r.body),
                              $path: () => `${prefix}${prefix8}`,
                            };
                          },
                          /**
                           * 週間トレーニング一覧を取得する。
                           *
                           * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                           * @returns 成功
                           */
                          get: (
                            option?: { config?: T | undefined } | undefined
                          ) =>
                            fetch<
                              Methods_1hyok68['get']['resBody'],
                              BasicHeaders,
                              Methods_1hyok68['get']['status']
                            >(
                              prefix,
                              `${prefix6}${PATH31}`,
                              GET,
                              option
                            ).json(),
                          /**
                           * 週間トレーニング一覧を取得する。
                           *
                           * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                           * @returns 成功
                           */
                          $get: (
                            option?: { config?: T | undefined } | undefined
                          ) =>
                            fetch<
                              Methods_1hyok68['get']['resBody'],
                              BasicHeaders,
                              Methods_1hyok68['get']['status']
                            >(prefix, `${prefix6}${PATH31}`, GET, option)
                              .json()
                              .then((r) => r.body),
                          $path: () => `${prefix}${prefix6}${PATH31}`,
                        },
                      };
                    },
                  },
                };
              },
            },
            /**
             * 生徒IDを指定して取得する。
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_wat3qk['get']['resBody'],
                BasicHeaders,
                Methods_wat3qk['get']['status']
              >(prefix, prefix2, GET, option).json(),
            /**
             * 生徒IDを指定して取得する。
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_wat3qk['get']['resBody'],
                BasicHeaders,
                Methods_wat3qk['get']['status']
              >(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            /**
             * 生徒の受講校舎、担当コンサルタントを更新する
             * @returns 成功
             */
            put: (option: {
              body: Methods_wat3qk['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_wat3qk['put']['resBody'],
                BasicHeaders,
                Methods_wat3qk['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * 生徒の受講校舎、担当コンサルタントを更新する
             * @returns 成功
             */
            $put: (option: {
              body: Methods_wat3qk['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_wat3qk['put']['resBody'],
                BasicHeaders,
                Methods_wat3qk['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 生徒一覧を条件を指定して取得する。
         * @returns 成功
         */
        get: (
          option?:
            | {
                query?: Methods_sbj4kw['get']['query'] | undefined;
                config?: T | undefined;
              }
            | undefined
        ) =>
          fetch<
            Methods_sbj4kw['get']['resBody'],
            BasicHeaders,
            Methods_sbj4kw['get']['status']
          >(prefix, PATH25, GET, option).json(),
        /**
         * 生徒一覧を条件を指定して取得する。
         * @returns 成功
         */
        $get: (
          option?:
            | {
                query?: Methods_sbj4kw['get']['query'] | undefined;
                config?: T | undefined;
              }
            | undefined
        ) =>
          fetch<
            Methods_sbj4kw['get']['resBody'],
            BasicHeaders,
            Methods_sbj4kw['get']['status']
          >(prefix, PATH25, GET, option)
            .json()
            .then((r) => r.body),
        $path: (
          option?:
            | {
                method?: 'get' | undefined;
                query: Methods_sbj4kw['get']['query'];
              }
            | undefined
        ) =>
          `${prefix}${PATH25}${
            option && option.query ? `?${dataToURLString(option.query)}` : ''
          }`,
      },
      study_items: {
        /**
         * 学習項目マスタ一覧を取得する。
         * @returns 成功
         */
        get: (option: {
          query: Methods_1nwuvb6['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_1nwuvb6['get']['resBody'],
            BasicHeaders,
            Methods_1nwuvb6['get']['status']
          >(prefix, PATH36, GET, option).json(),
        /**
         * 学習項目マスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option: {
          query: Methods_1nwuvb6['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_1nwuvb6['get']['resBody'],
            BasicHeaders,
            Methods_1nwuvb6['get']['status']
          >(prefix, PATH36, GET, option)
            .json()
            .then((r) => r.body),
        $path: (
          option?:
            | {
                method?: 'get' | undefined;
                query: Methods_1nwuvb6['get']['query'];
              }
            | undefined
        ) =>
          `${prefix}${PATH36}${
            option && option.query ? `?${dataToURLString(option.query)}` : ''
          }`,
      },
      study_materials: {
        /**
         * 学習教材マスタ一覧を取得する。
         * @returns 成功
         */
        get: (option: {
          query: Methods_1mrset4['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_1mrset4['get']['resBody'],
            BasicHeaders,
            Methods_1mrset4['get']['status']
          >(prefix, PATH37, GET, option).json(),
        /**
         * 学習教材マスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option: {
          query: Methods_1mrset4['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_1mrset4['get']['resBody'],
            BasicHeaders,
            Methods_1mrset4['get']['status']
          >(prefix, PATH37, GET, option)
            .json()
            .then((r) => r.body),
        $path: (
          option?:
            | {
                method?: 'get' | undefined;
                query: Methods_1mrset4['get']['query'];
              }
            | undefined
        ) =>
          `${prefix}${PATH37}${
            option && option.query ? `?${dataToURLString(option.query)}` : ''
          }`,
      },
      study_menus: {
        /**
         * 学習メニューマスタ一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_155drpa['get']['resBody'],
            BasicHeaders,
            Methods_155drpa['get']['status']
          >(prefix, PATH38, GET, option).json(),
        /**
         * 学習メニューマスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_155drpa['get']['resBody'],
            BasicHeaders,
            Methods_155drpa['get']['status']
          >(prefix, PATH38, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH38}`,
      },
    },
    v2: {
      schedules: {
        _schedule_id: (val2: number) => {
          const prefix2 = `${PATH39}/${val2}`;

          return {
            info: {
              /**
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1gs82eu['get']['resBody'],
                  BasicHeaders,
                  Methods_1gs82eu['get']['status']
                >(prefix, `${prefix2}${PATH40}`, GET, option).json(),
              /**
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1gs82eu['get']['resBody'],
                  BasicHeaders,
                  Methods_1gs82eu['get']['status']
                >(prefix, `${prefix2}${PATH40}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH40}`,
            },
          };
        },
      },
      shadowing: {
        patrol_output: {
          /**
           * パト出力情報を取得する。
           *
           * 添削タグの指定値によらず一律未添削データのみ対象とする。
           * @returns 成功
           */
          get: (
            option?:
              | {
                  query?: Methods_jyeupg['get']['query'] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_jyeupg['get']['resBody'],
              BasicHeaders,
              Methods_jyeupg['get']['status']
            >(prefix, PATH41, GET, option).json(),
          /**
           * パト出力情報を取得する。
           *
           * 添削タグの指定値によらず一律未添削データのみ対象とする。
           * @returns 成功
           */
          $get: (
            option?:
              | {
                  query?: Methods_jyeupg['get']['query'] | undefined;
                  config?: T | undefined;
                }
              | undefined
          ) =>
            fetch<
              Methods_jyeupg['get']['resBody'],
              BasicHeaders,
              Methods_jyeupg['get']['status']
            >(prefix, PATH41, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: 'get' | undefined;
                  query: Methods_jyeupg['get']['query'];
                }
              | undefined
          ) =>
            `${prefix}${PATH41}${
              option && option.query ? `?${dataToURLString(option.query)}` : ''
            }`,
        },
        students: {
          _progrit_student_id: (val3: number) => {
            const prefix3 = `${PATH42}/${val3}`;

            return {
              communication: {
                /**
                 * 生徒の伝達事項を更新
                 * @returns 成功
                 */
                put: (option: {
                  body: Methods_1syjv4t['put']['reqBody'];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1syjv4t['put']['resBody'],
                    BasicHeaders,
                    Methods_1syjv4t['put']['status']
                  >(prefix, `${prefix3}${PATH43}`, PUT, option).json(),
                /**
                 * 生徒の伝達事項を更新
                 * @returns 成功
                 */
                $put: (option: {
                  body: Methods_1syjv4t['put']['reqBody'];
                  config?: T | undefined;
                }) =>
                  fetch<
                    Methods_1syjv4t['put']['resBody'],
                    BasicHeaders,
                    Methods_1syjv4t['put']['status']
                  >(prefix, `${prefix3}${PATH43}`, PUT, option)
                    .json()
                    .then((r) => r.body),
                $path: () => `${prefix}${prefix3}${PATH43}`,
              },
              statistics: {
                /**
                 * @returns 成功
                 */
                get: (
                  option?:
                    | {
                        query?: Methods_dn4xlk['get']['query'] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_dn4xlk['get']['resBody'],
                    BasicHeaders,
                    Methods_dn4xlk['get']['status']
                  >(prefix, `${prefix3}${PATH44}`, GET, option).json(),
                /**
                 * @returns 成功
                 */
                $get: (
                  option?:
                    | {
                        query?: Methods_dn4xlk['get']['query'] | undefined;
                        config?: T | undefined;
                      }
                    | undefined
                ) =>
                  fetch<
                    Methods_dn4xlk['get']['resBody'],
                    BasicHeaders,
                    Methods_dn4xlk['get']['status']
                  >(prefix, `${prefix3}${PATH44}`, GET, option)
                    .json()
                    .then((r) => r.body),
                $path: (
                  option?:
                    | {
                        method?: 'get' | undefined;
                        query: Methods_dn4xlk['get']['query'];
                      }
                    | undefined
                ) =>
                  `${prefix}${prefix3}${PATH44}${
                    option && option.query
                      ? `?${dataToURLString(option.query)}`
                      : ''
                  }`,
              },
              /**
               * シャドーイング添削の生徒情報を取得する。
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_15c3h3y['get']['resBody'],
                  BasicHeaders,
                  Methods_15c3h3y['get']['status']
                >(prefix, prefix3, GET, option).json(),
              /**
               * シャドーイング添削の生徒情報を取得する。
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_15c3h3y['get']['resBody'],
                  BasicHeaders,
                  Methods_15c3h3y['get']['status']
                >(prefix, prefix3, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          /**
           * シャドーイング添削の生徒一覧を取得する。
           *
           * ### ステータス 検索仕様
           *
           * - テーブル名student_coursesにおいて、下記2条件をともに満足するときステータスは受講中となり、受講中と判定されたコースを添削アクティブコースと呼ぶ。
           *   ```
           *     [受講中の条件]
           *       ①解約日を過ぎていない
           *         Today < 解約日 + 1
           *         ※解約日当日は受講中とみなさない
           *
           *       ②開始日〜終了日の中に収まっている
           *         SFプログラム開始日 ≦ Today ≦ SFプログラム終了日 + 1
           *         ※開始日および終了日当日は受講中とみなす
           *
           *     [補足条件]
           *       - SFプログラム開始日がnullの場合 →「非受講」
           *       - SFプログラム終了日がnullの場合 →「開始日〜終了日の中に収まっている」
           *       - SF解約日がnullの場合 →「解約日を過ぎていない」
           *   ```
           *
           * - 添削アクティブコースが一つでもあれば当該生徒のステータスは受講中となり、シャドーイング添削の検索条件「ステータス」における受講中で表示対象となる。
           *
           * ### 添削タグ 検索仕様
           *
           * - 未添削: 未添削が一つ以上存在する生徒
           * - 添削済み: すべての録音音声が添削済みの生徒
           *
           * ### コース検索 検索仕様
           *
           * - アクティブでないコースも検索対象とする
           *
           * ### レスポンス
           *
           * - `student_shadowing_correction`フィールド
           *   - 未添削音声が存在する場合
           *     - 最も古い未添削音声の添削データを返す
           *   - 未添削音声が存在しない場合
           *     - 添削済み or 対応不要の音声のうち最も送信時間が新しい添削データを返す
           *
           * 生徒一覧の仕様詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#2.%20%E7%94%9F%E5%BE%92%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
           *
           * ステータス検索(添削アクティブコース)の詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削_アクティブコース](https://progrit-dev.esa.io/posts/185)
           *
           * nextLink: 次ページのデータのリンク
           * @returns 成功
           */
          get: (option: {
            query: Methods_j8byqu['get']['query'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_j8byqu['get']['resBody'],
              BasicHeaders,
              Methods_j8byqu['get']['status']
            >(prefix, PATH42, GET, option).json(),
          /**
           * シャドーイング添削の生徒一覧を取得する。
           *
           * ### ステータス 検索仕様
           *
           * - テーブル名student_coursesにおいて、下記2条件をともに満足するときステータスは受講中となり、受講中と判定されたコースを添削アクティブコースと呼ぶ。
           *   ```
           *     [受講中の条件]
           *       ①解約日を過ぎていない
           *         Today < 解約日 + 1
           *         ※解約日当日は受講中とみなさない
           *
           *       ②開始日〜終了日の中に収まっている
           *         SFプログラム開始日 ≦ Today ≦ SFプログラム終了日 + 1
           *         ※開始日および終了日当日は受講中とみなす
           *
           *     [補足条件]
           *       - SFプログラム開始日がnullの場合 →「非受講」
           *       - SFプログラム終了日がnullの場合 →「開始日〜終了日の中に収まっている」
           *       - SF解約日がnullの場合 →「解約日を過ぎていない」
           *   ```
           *
           * - 添削アクティブコースが一つでもあれば当該生徒のステータスは受講中となり、シャドーイング添削の検索条件「ステータス」における受講中で表示対象となる。
           *
           * ### 添削タグ 検索仕様
           *
           * - 未添削: 未添削が一つ以上存在する生徒
           * - 添削済み: すべての録音音声が添削済みの生徒
           *
           * ### コース検索 検索仕様
           *
           * - アクティブでないコースも検索対象とする
           *
           * ### レスポンス
           *
           * - `student_shadowing_correction`フィールド
           *   - 未添削音声が存在する場合
           *     - 最も古い未添削音声の添削データを返す
           *   - 未添削音声が存在しない場合
           *     - 添削済み or 対応不要の音声のうち最も送信時間が新しい添削データを返す
           *
           * 生徒一覧の仕様詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#2.%20%E7%94%9F%E5%BE%92%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
           *
           * ステータス検索(添削アクティブコース)の詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削_アクティブコース](https://progrit-dev.esa.io/posts/185)
           *
           * nextLink: 次ページのデータのリンク
           * @returns 成功
           */
          $get: (option: {
            query: Methods_j8byqu['get']['query'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_j8byqu['get']['resBody'],
              BasicHeaders,
              Methods_j8byqu['get']['status']
            >(prefix, PATH42, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: 'get' | undefined;
                  query: Methods_j8byqu['get']['query'];
                }
              | undefined
          ) =>
            `${prefix}${PATH42}${
              option && option.query ? `?${dataToURLString(option.query)}` : ''
            }`,
        },
      },
      shadowing_corrections: {
        _shadowing_correction_id: (val2: number) => {
          const prefix2 = `${PATH45}/${val2}`;

          return {
            kanafuri_set: {
              /**
               * シャドーイング添削に紐づくカナフリセットを取得する。
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1trugvk['get']['resBody'],
                  BasicHeaders,
                  Methods_1trugvk['get']['status']
                >(prefix, `${prefix2}${PATH21}`, GET, option).json(),
              /**
               * シャドーイング添削に紐づくカナフリセットを取得する。
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1trugvk['get']['resBody'],
                  BasicHeaders,
                  Methods_1trugvk['get']['status']
                >(prefix, `${prefix2}${PATH21}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH21}`,
            },
            /**
             * シャドーイング添削詳細を取得する。
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_176hooh['get']['resBody'],
                BasicHeaders,
                Methods_176hooh['get']['status']
              >(prefix, prefix2, GET, option).json(),
            /**
             * シャドーイング添削詳細を取得する。
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_176hooh['get']['resBody'],
                BasicHeaders,
                Methods_176hooh['get']['status']
              >(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            /**
             * シャドーイング添削詳細を更新する。
             *
             * 下記のユースケースに合わせて更新対象のパラメータを指定する。
             *
             * | ユースケース | 対象パラメータ |
             * | --- | --- |
             * | 添削テキスト更新 | correction_text |
             * | 対応中フラグ更新 | assigned_consultant_id |
             * | 添削タグ更新 | status |
             *
             * パラメータで指定されたrevisionとDBの `revision` が一致する場合のみ、更新を行う。(このときrevisionをインクリメントする)
             *
             * サーバーで発行した `corrected_at`, `updated_at` を返す必要があるため、更新成功時は最新の添削データを返す。
             *
             * `revision` が一致しない場合、HTTPステータス:409、レスポンスボディ: `updated_consultant_id`, `updated_consultant_name`, `updated_at` を返す。
             * @returns 成功
             */
            put: (option: {
              body: Methods_176hooh['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_176hooh['put']['resBody'],
                BasicHeaders,
                Methods_176hooh['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * シャドーイング添削詳細を更新する。
             *
             * 下記のユースケースに合わせて更新対象のパラメータを指定する。
             *
             * | ユースケース | 対象パラメータ |
             * | --- | --- |
             * | 添削テキスト更新 | correction_text |
             * | 対応中フラグ更新 | assigned_consultant_id |
             * | 添削タグ更新 | status |
             *
             * パラメータで指定されたrevisionとDBの `revision` が一致する場合のみ、更新を行う。(このときrevisionをインクリメントする)
             *
             * サーバーで発行した `corrected_at`, `updated_at` を返す必要があるため、更新成功時は最新の添削データを返す。
             *
             * `revision` が一致しない場合、HTTPステータス:409、レスポンスボディ: `updated_consultant_id`, `updated_consultant_name`, `updated_at` を返す。
             * @returns 成功
             */
            $put: (option: {
              body: Methods_176hooh['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_176hooh['put']['resBody'],
                BasicHeaders,
                Methods_176hooh['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
      shadowing_notes: {
        _shadowing_note_id: (val2: number) => {
          const prefix2 = `${PATH46}/${val2}`;

          return {
            /**
             * シャドーイングノート詳細を取得する。
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_6oukr9['get']['resBody'],
                BasicHeaders,
                Methods_6oukr9['get']['status']
              >(prefix, prefix2, GET, option).json(),
            /**
             * シャドーイングノート詳細を取得する。
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_6oukr9['get']['resBody'],
                BasicHeaders,
                Methods_6oukr9['get']['status']
              >(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            /**
             * シャドーイングノートを更新する。
             * @returns 成功
             */
            put: (option: {
              body: Methods_6oukr9['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_6oukr9['put']['resBody'],
                BasicHeaders,
                Methods_6oukr9['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * シャドーイングノートを更新する。
             * @returns 成功
             */
            $put: (option: {
              body: Methods_6oukr9['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_6oukr9['put']['resBody'],
                BasicHeaders,
                Methods_6oukr9['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * シャドーイングノートを削除する。
             * @returns 成功
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_6oukr9['delete']['resBody'],
                BasicHeaders,
                Methods_6oukr9['delete']['status']
              >(prefix, prefix2, DELETE, option).json(),
            /**
             * シャドーイングノートを削除する。
             * @returns 成功
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_6oukr9['delete']['resBody'],
                BasicHeaders,
                Methods_6oukr9['delete']['status']
              >(prefix, prefix2, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
      shadowing_recordings: {
        _student_shadowing_recording_id: (val2: number) => {
          const prefix2 = `${PATH47}/${val2}`;

          return {
            file: {
              /**
               * シャドーイング録音音声ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1vtrw5w['get']['resBody'],
                  BasicHeaders,
                  Methods_1vtrw5w['get']['status']
                >(prefix, `${prefix2}${PATH48}`, GET, option).blob(),
              /**
               * シャドーイング録音音声ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1vtrw5w['get']['resBody'],
                  BasicHeaders,
                  Methods_1vtrw5w['get']['status']
                >(prefix, `${prefix2}${PATH48}`, GET, option)
                  .blob()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH48}`,
            },
          };
        },
      },
      students: {
        _progrit_student_id: (val2: number) => {
          const prefix2 = `${PATH49}/${val2}`;

          return {
            shadowing_corrections: {
              /**
               * シャドーイング添削一覧を取得する。
               *
               * - created_atの降順 (新着順) にソートして返す
               * - 1ページの件数は50件
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#3.%20%E9%9F%B3%E5%A3%B0%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
               * @returns 成功
               */
              get: (
                option?:
                  | {
                      query?: Methods_ljvicw['get']['query'] | undefined;
                      config?: T | undefined;
                    }
                  | undefined
              ) =>
                fetch<
                  Methods_ljvicw['get']['resBody'],
                  BasicHeaders,
                  Methods_ljvicw['get']['status']
                >(prefix, `${prefix2}${PATH50}`, GET, option).json(),
              /**
               * シャドーイング添削一覧を取得する。
               *
               * - created_atの降順 (新着順) にソートして返す
               * - 1ページの件数は50件
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#3.%20%E9%9F%B3%E5%A3%B0%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
               * @returns 成功
               */
              $get: (
                option?:
                  | {
                      query?: Methods_ljvicw['get']['query'] | undefined;
                      config?: T | undefined;
                    }
                  | undefined
              ) =>
                fetch<
                  Methods_ljvicw['get']['resBody'],
                  BasicHeaders,
                  Methods_ljvicw['get']['status']
                >(prefix, `${prefix2}${PATH50}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: (
                option?:
                  | {
                      method?: 'get' | undefined;
                      query: Methods_ljvicw['get']['query'];
                    }
                  | undefined
              ) =>
                `${prefix}${prefix2}${PATH50}${
                  option && option.query
                    ? `?${dataToURLString(option.query)}`
                    : ''
                }`,
            },
            shadowing_notes: {
              /**
               * シャドーイングノート一覧を取得する。
               *
               * - updated_atの降順 (更新順) にソートして返す
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#%E3%83%8E%E3%83%BC%E3%83%88)
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1jfcvc['get']['resBody'],
                  BasicHeaders,
                  Methods_1jfcvc['get']['status']
                >(prefix, `${prefix2}${PATH51}`, GET, option).json(),
              /**
               * シャドーイングノート一覧を取得する。
               *
               * - updated_atの降順 (更新順) にソートして返す
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#%E3%83%8E%E3%83%BC%E3%83%88)
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1jfcvc['get']['resBody'],
                  BasicHeaders,
                  Methods_1jfcvc['get']['status']
                >(prefix, `${prefix2}${PATH51}`, GET, option)
                  .json()
                  .then((r) => r.body),
              /**
               * シャドーイングノートを登録する。
               * @returns 成功
               */
              post: (option: {
                body: Methods_1jfcvc['post']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_1jfcvc['post']['resBody'],
                  BasicHeaders,
                  Methods_1jfcvc['post']['status']
                >(prefix, `${prefix2}${PATH51}`, POST, option).json(),
              /**
               * シャドーイングノートを登録する。
               * @returns 成功
               */
              $post: (option: {
                body: Methods_1jfcvc['post']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_1jfcvc['post']['resBody'],
                  BasicHeaders,
                  Methods_1jfcvc['post']['status']
                >(prefix, `${prefix2}${PATH51}`, POST, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH51}`,
            },
          };
        },
        _student_id: (val2: string) => {
          const prefix2 = `${PATH49}/${val2}`;

          return {
            schedules: {
              _schedule_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH29}/${val4}`;

                return {
                  entire_schedule: {
                    /**
                     * 指定されたスケジュールの、全体スケジュール取得を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1dw8zrq['get']['resBody'],
                        BasicHeaders,
                        Methods_1dw8zrq['get']['status']
                      >(prefix, `${prefix4}${PATH52}`, GET, option).json(),
                    /**
                     * 指定されたスケジュールの、全体スケジュール取得を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1dw8zrq['get']['resBody'],
                        BasicHeaders,
                        Methods_1dw8zrq['get']['status']
                      >(prefix, `${prefix4}${PATH52}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH52}`,
                  },
                  study_menus: {
                    _schedule_study_menu_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH53}/${val6}`;

                      return {
                        /**
                         * 指定されたスケジュールのスケジュール学習メニュー設定を取得する。
                         * @returns 成功
                         */
                        get: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1mflgbt['get']['resBody'],
                            BasicHeaders,
                            Methods_1mflgbt['get']['status']
                          >(prefix, prefix6, GET, option).json(),
                        /**
                         * 指定されたスケジュールのスケジュール学習メニュー設定を取得する。
                         * @returns 成功
                         */
                        $get: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1mflgbt['get']['resBody'],
                            BasicHeaders,
                            Methods_1mflgbt['get']['status']
                          >(prefix, prefix6, GET, option)
                            .json()
                            .then((r) => r.body),
                        /**
                         * スケジュール学習メニュー設定(5ステップのメニュー)を削除する。
                         *
                         * 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングに紐づく学習予定、学習記録が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1mflgbt['delete']['resBody'],
                            BasicHeaders,
                            Methods_1mflgbt['delete']['status']
                          >(prefix, prefix6, DELETE, option).json(),
                        /**
                         * スケジュール学習メニュー設定(5ステップのメニュー)を削除する。
                         *
                         * 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングに紐づく学習予定、学習記録が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        $delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1mflgbt['delete']['resBody'],
                            BasicHeaders,
                            Methods_1mflgbt['delete']['status']
                          >(prefix, prefix6, DELETE, option)
                            .json()
                            .then((r) => r.body),
                        $path: () => `${prefix}${prefix6}`,
                      };
                    },
                    /**
                     * 指定されたスケジュールのスケジュール学習メニュー設定一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1p4zetr['get']['resBody'],
                        BasicHeaders,
                        Methods_1p4zetr['get']['status']
                      >(prefix, `${prefix4}${PATH53}`, GET, option).json(),
                    /**
                     * 指定されたスケジュールのスケジュール学習メニュー設定一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1p4zetr['get']['resBody'],
                        BasicHeaders,
                        Methods_1p4zetr['get']['status']
                      >(prefix, `${prefix4}${PATH53}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    /**
                     * スケジュール学習メニュー設定を登録する。
                     * @returns 成功
                     */
                    post: (option: {
                      body: Methods_1p4zetr['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_1p4zetr['post']['resBody'],
                        BasicHeaders,
                        Methods_1p4zetr['post']['status']
                      >(prefix, `${prefix4}${PATH53}`, POST, option).json(),
                    /**
                     * スケジュール学習メニュー設定を登録する。
                     * @returns 成功
                     */
                    $post: (option: {
                      body: Methods_1p4zetr['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_1p4zetr['post']['resBody'],
                        BasicHeaders,
                        Methods_1p4zetr['post']['status']
                      >(prefix, `${prefix4}${PATH53}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH53}`,
                  },
                  weekly_interviews: {
                    /**
                     * 面談情報を週べて取得する
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_2qgt5d['get']['resBody'],
                        BasicHeaders,
                        Methods_2qgt5d['get']['status']
                      >(prefix, `${prefix4}${PATH54}`, GET, option).json(),
                    /**
                     * 面談情報を週べて取得する
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_2qgt5d['get']['resBody'],
                        BasicHeaders,
                        Methods_2qgt5d['get']['status']
                      >(prefix, `${prefix4}${PATH54}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH54}`,
                  },
                  weekly_study_status: {
                    /**
                     * ScheduleIdに紐づく学習状況をweeklyですべて取得する
                     * 週単位で集計された情報を返却する
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_abdieh['get']['resBody'],
                        BasicHeaders,
                        Methods_abdieh['get']['status']
                      >(prefix, `${prefix4}${PATH55}`, GET, option).json(),
                    /**
                     * ScheduleIdに紐づく学習状況をweeklyですべて取得する
                     * 週単位で集計された情報を返却する
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_abdieh['get']['resBody'],
                        BasicHeaders,
                        Methods_abdieh['get']['status']
                      >(prefix, `${prefix4}${PATH55}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH55}`,
                  },
                  weeks: {
                    _week: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH35}/${val6}`;

                      return {
                        interview: {
                          /**
                           * 週間面談詳細を取得する
                           * @returns 成功
                           */
                          get: (
                            option?: { config?: T | undefined } | undefined
                          ) =>
                            fetch<
                              Methods_jks3qn['get']['resBody'],
                              BasicHeaders,
                              Methods_jks3qn['get']['status']
                            >(
                              prefix,
                              `${prefix6}${PATH56}`,
                              GET,
                              option
                            ).json(),
                          /**
                           * 週間面談詳細を取得する
                           * @returns 成功
                           */
                          $get: (
                            option?: { config?: T | undefined } | undefined
                          ) =>
                            fetch<
                              Methods_jks3qn['get']['resBody'],
                              BasicHeaders,
                              Methods_jks3qn['get']['status']
                            >(prefix, `${prefix6}${PATH56}`, GET, option)
                              .json()
                              .then((r) => r.body),
                          /**
                           * 週間面談詳細を作成・または更新を行う。
                           *
                           * 面談情報は1つしか登録できないため、作成と更新の両方をこのメソッドで処理する(POST)
                           * @returns 新たにリソースを作成した場合
                           */
                          post: (option: {
                            body: Methods_jks3qn['post']['reqBody'];
                            config?: T | undefined;
                          }) =>
                            fetch<
                              Methods_jks3qn['post']['resBody'],
                              BasicHeaders,
                              Methods_jks3qn['post']['status']
                            >(
                              prefix,
                              `${prefix6}${PATH56}`,
                              POST,
                              option
                            ).json(),
                          /**
                           * 週間面談詳細を作成・または更新を行う。
                           *
                           * 面談情報は1つしか登録できないため、作成と更新の両方をこのメソッドで処理する(POST)
                           * @returns 新たにリソースを作成した場合
                           */
                          $post: (option: {
                            body: Methods_jks3qn['post']['reqBody'];
                            config?: T | undefined;
                          }) =>
                            fetch<
                              Methods_jks3qn['post']['resBody'],
                              BasicHeaders,
                              Methods_jks3qn['post']['status']
                            >(prefix, `${prefix6}${PATH56}`, POST, option)
                              .json()
                              .then((r) => r.body),
                          $path: () => `${prefix}${prefix6}${PATH56}`,
                        },
                        study_status: {
                          /**
                           * 指定した週番号の学習状況を取得する。
                           * 日単位で集計された情報を返却する
                           * @returns 成功
                           */
                          get: (
                            option?: { config?: T | undefined } | undefined
                          ) =>
                            fetch<
                              Methods_aszbus['get']['resBody'],
                              BasicHeaders,
                              Methods_aszbus['get']['status']
                            >(
                              prefix,
                              `${prefix6}${PATH57}`,
                              GET,
                              option
                            ).json(),
                          /**
                           * 指定した週番号の学習状況を取得する。
                           * 日単位で集計された情報を返却する
                           * @returns 成功
                           */
                          $get: (
                            option?: { config?: T | undefined } | undefined
                          ) =>
                            fetch<
                              Methods_aszbus['get']['resBody'],
                              BasicHeaders,
                              Methods_aszbus['get']['status']
                            >(prefix, `${prefix6}${PATH57}`, GET, option)
                              .json()
                              .then((r) => r.body),
                          $path: () => `${prefix}${prefix6}${PATH57}`,
                        },
                        /**
                         * 指定されたスケジュールのスケジュール週設定を取得する。
                         * @returns 成功
                         */
                        get: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ycf00z['get']['resBody'],
                            BasicHeaders,
                            Methods_ycf00z['get']['status']
                          >(prefix, prefix6, GET, option).json(),
                        /**
                         * 指定されたスケジュールのスケジュール週設定を取得する。
                         * @returns 成功
                         */
                        $get: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ycf00z['get']['resBody'],
                            BasicHeaders,
                            Methods_ycf00z['get']['status']
                          >(prefix, prefix6, GET, option)
                            .json()
                            .then((r) => r.body),
                        /**
                         * スケジュール週設定を削除する。
                         *
                         * ### 削除可能条件
                         * - 最終週かつ最終週にトレーニングが登録されていないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ycf00z['delete']['resBody'],
                            BasicHeaders,
                            Methods_ycf00z['delete']['status']
                          >(prefix, prefix6, DELETE, option).json(),
                        /**
                         * スケジュール週設定を削除する。
                         *
                         * ### 削除可能条件
                         * - 最終週かつ最終週にトレーニングが登録されていないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        $delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_ycf00z['delete']['resBody'],
                            BasicHeaders,
                            Methods_ycf00z['delete']['status']
                          >(prefix, prefix6, DELETE, option)
                            .json()
                            .then((r) => r.body),
                        $path: () => `${prefix}${prefix6}`,
                      };
                    },
                    /**
                     * 指定されたスケジュールのスケジュール週設定一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_o2vuco['get']['resBody'],
                        BasicHeaders,
                        Methods_o2vuco['get']['status']
                      >(prefix, `${prefix4}${PATH35}`, GET, option).json(),
                    /**
                     * 指定されたスケジュールのスケジュール週設定一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_o2vuco['get']['resBody'],
                        BasicHeaders,
                        Methods_o2vuco['get']['status']
                      >(prefix, `${prefix4}${PATH35}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    /**
                     * スケジュール週設定を登録する
                     * @returns 成功
                     */
                    post: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_o2vuco['post']['resBody'],
                        BasicHeaders,
                        Methods_o2vuco['post']['status']
                      >(prefix, `${prefix4}${PATH35}`, POST, option).json(),
                    /**
                     * スケジュール週設定を登録する
                     * @returns 成功
                     */
                    $post: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_o2vuco['post']['resBody'],
                        BasicHeaders,
                        Methods_o2vuco['post']['status']
                      >(prefix, `${prefix4}${PATH35}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH35}`,
                  },
                  wpm_transitions: {
                    /**
                     * 学習記録(速読)のWPM推移一覧を取得する。
                     *
                     * グラフ表示は最大2回目までの仕様のため、study_countが3以上のデータが存在してもレスポンスでは返さない。
                     *
                     * レスポンスの参照フィールドは下記URLを参照。
                     *
                     * <img src="./images/wpm_transition.png">
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1qbyfo['get']['resBody'],
                        BasicHeaders,
                        Methods_1qbyfo['get']['status']
                      >(prefix, `${prefix4}${PATH58}`, GET, option).json(),
                    /**
                     * 学習記録(速読)のWPM推移一覧を取得する。
                     *
                     * グラフ表示は最大2回目までの仕様のため、study_countが3以上のデータが存在してもレスポンスでは返さない。
                     *
                     * レスポンスの参照フィールドは下記URLを参照。
                     *
                     * <img src="./images/wpm_transition.png">
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_1qbyfo['get']['resBody'],
                        BasicHeaders,
                        Methods_1qbyfo['get']['status']
                      >(prefix, `${prefix4}${PATH58}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH58}`,
                  },
                };
              },
            },
            student_courses: {
              _student_course_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH59}/${val4}`;

                return {
                  versant_exam_records: {
                    _versant_exam_record_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH32}/${val6}`;

                      return {
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        put: (option: {
                          body: Methods_1dqp7q1['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_1dqp7q1['put']['resBody'],
                            BasicHeaders,
                            Methods_1dqp7q1['put']['status']
                          >(prefix, prefix6, PUT, option).json(),
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        $put: (option: {
                          body: Methods_1dqp7q1['put']['reqBody'];
                          config?: T | undefined;
                        }) =>
                          fetch<
                            Methods_1dqp7q1['put']['resBody'],
                            BasicHeaders,
                            Methods_1dqp7q1['put']['status']
                          >(prefix, prefix6, PUT, option)
                            .json()
                            .then((r) => r.body),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1dqp7q1['delete']['resBody'],
                            BasicHeaders,
                            Methods_1dqp7q1['delete']['status']
                          >(prefix, prefix6, DELETE, option).json(),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        $delete: (
                          option?: { config?: T | undefined } | undefined
                        ) =>
                          fetch<
                            Methods_1dqp7q1['delete']['resBody'],
                            BasicHeaders,
                            Methods_1dqp7q1['delete']['status']
                          >(prefix, prefix6, DELETE, option)
                            .json()
                            .then((r) => r.body),
                        $path: () => `${prefix}${prefix6}`,
                      };
                    },
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_rcwznd['get']['resBody'],
                        BasicHeaders,
                        Methods_rcwznd['get']['status']
                      >(prefix, `${prefix4}${PATH32}`, GET, option).json(),
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<
                        Methods_rcwznd['get']['resBody'],
                        BasicHeaders,
                        Methods_rcwznd['get']['status']
                      >(prefix, `${prefix4}${PATH32}`, GET, option)
                        .json()
                        .then((r) => r.body),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    post: (option: {
                      body: Methods_rcwznd['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_rcwznd['post']['resBody'],
                        BasicHeaders,
                        Methods_rcwznd['post']['status']
                      >(prefix, `${prefix4}${PATH32}`, POST, option).json(),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    $post: (option: {
                      body: Methods_rcwznd['post']['reqBody'];
                      config?: T | undefined;
                    }) =>
                      fetch<
                        Methods_rcwznd['post']['resBody'],
                        BasicHeaders,
                        Methods_rcwznd['post']['status']
                      >(prefix, `${prefix4}${PATH32}`, POST, option)
                        .json()
                        .then((r) => r.body),
                    $path: () => `${prefix}${prefix4}${PATH32}`,
                  },
                };
              },
            },
          };
        },
      },
    },
    v3: {
      data: {
        consultants: {
          sync: {
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/16bxz9iwftTKD3eFdqrzUQNxAqS1893iMqKNRkA8uz8Q/edit#gid=1885331552)
             * @returns 成功
             */
            post: (option: {
              body: Methods_18h6a4n['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_18h6a4n['post']['resBody'],
                BasicHeaders,
                Methods_18h6a4n['post']['status']
              >(prefix, PATH60, POST, option).json(),
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/16bxz9iwftTKD3eFdqrzUQNxAqS1893iMqKNRkA8uz8Q/edit#gid=1885331552)
             * @returns 成功
             */
            $post: (option: {
              body: Methods_18h6a4n['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_18h6a4n['post']['resBody'],
                BasicHeaders,
                Methods_18h6a4n['post']['status']
              >(prefix, PATH60, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH60}`,
          },
        },
      },
      schedules: {
        _schedule_id: (val2: number) => {
          const prefix2 = `${PATH61}/${val2}`;

          return {
            conversation_study_records: {
              /**
               * オンライン英会話 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_t1ouer['get']['resBody'],
                  BasicHeaders,
                  Methods_t1ouer['get']['status']
                >(prefix, `${prefix2}${PATH62}`, GET, option).json(),
              /**
               * オンライン英会話 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_t1ouer['get']['resBody'],
                  BasicHeaders,
                  Methods_t1ouer['get']['status']
                >(prefix, `${prefix2}${PATH62}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH62}`,
            },
            entire_schedule: {
              /**
               * 指定されたスケジュールの、全体スケジュール取得を取得する。
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_176o0je['get']['resBody'],
                  BasicHeaders,
                  Methods_176o0je['get']['status']
                >(prefix, `${prefix2}${PATH52}`, GET, option).json(),
              /**
               * 指定されたスケジュールの、全体スケジュール取得を取得する。
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_176o0je['get']['resBody'],
                  BasicHeaders,
                  Methods_176o0je['get']['status']
                >(prefix, `${prefix2}${PATH52}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH52}`,
            },
            exam_study_records: {
              /**
               * 模試 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_b3m4n3['get']['resBody'],
                  BasicHeaders,
                  Methods_b3m4n3['get']['status']
                >(prefix, `${prefix2}${PATH63}`, GET, option).json(),
              /**
               * 模試 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_b3m4n3['get']['resBody'],
                  BasicHeaders,
                  Methods_b3m4n3['get']['status']
                >(prefix, `${prefix2}${PATH63}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH63}`,
            },
            extensive_reading_study_records: {
              /**
               * 多読 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1pknytc['get']['resBody'],
                  BasicHeaders,
                  Methods_1pknytc['get']['status']
                >(prefix, `${prefix2}${PATH64}`, GET, option).json(),
              /**
               * 多読 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1pknytc['get']['resBody'],
                  BasicHeaders,
                  Methods_1pknytc['get']['status']
                >(prefix, `${prefix2}${PATH64}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH64}`,
            },
            grammar_study_records: {
              /**
               * 文法 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1o6odmr['get']['resBody'],
                  BasicHeaders,
                  Methods_1o6odmr['get']['status']
                >(prefix, `${prefix2}${PATH65}`, GET, option).json(),
              /**
               * 文法 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_1o6odmr['get']['resBody'],
                  BasicHeaders,
                  Methods_1o6odmr['get']['status']
                >(prefix, `${prefix2}${PATH65}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH65}`,
            },
            intensive_reading_study_records: {
              /**
               * 精読 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_15e7g5m['get']['resBody'],
                  BasicHeaders,
                  Methods_15e7g5m['get']['status']
                >(prefix, `${prefix2}${PATH66}`, GET, option).json(),
              /**
               * 精読 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_15e7g5m['get']['resBody'],
                  BasicHeaders,
                  Methods_15e7g5m['get']['status']
                >(prefix, `${prefix2}${PATH66}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH66}`,
            },
            speed_reading_study_records: {
              /**
               * 速読 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_xhitra['get']['resBody'],
                  BasicHeaders,
                  Methods_xhitra['get']['status']
                >(prefix, `${prefix2}${PATH67}`, GET, option).json(),
              /**
               * 速読 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_xhitra['get']['resBody'],
                  BasicHeaders,
                  Methods_xhitra['get']['status']
                >(prefix, `${prefix2}${PATH67}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH67}`,
            },
          };
        },
      },
      trainings: {
        _training_id: (val2: number) => {
          const prefix2 = `${PATH68}/${val2}`;

          return {
            has_plan: {
              /**
               * トレーニングの指定期間内に学習プランの存在をチェックします
               * @returns 成功
               */
              get: (option: {
                query: Methods_vbvamz['get']['query'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_vbvamz['get']['resBody'],
                  BasicHeaders,
                  Methods_vbvamz['get']['status']
                >(prefix, `${prefix2}${PATH69}`, GET, option).json(),
              /**
               * トレーニングの指定期間内に学習プランの存在をチェックします
               * @returns 成功
               */
              $get: (option: {
                query: Methods_vbvamz['get']['query'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_vbvamz['get']['resBody'],
                  BasicHeaders,
                  Methods_vbvamz['get']['status']
                >(prefix, `${prefix2}${PATH69}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: (
                option?:
                  | {
                      method?: 'get' | undefined;
                      query: Methods_vbvamz['get']['query'];
                    }
                  | undefined
              ) =>
                `${prefix}${prefix2}${PATH69}${
                  option && option.query
                    ? `?${dataToURLString(option.query)}`
                    : ''
                }`,
            },
          };
        },
      },
    },
    v4: {
      schedules: {
        _schedule_id: (val2: number) => {
          const prefix2 = `${PATH70}/${val2}`;

          return {
            extensive_reading_study_records: {
              /**
               * 多読 学習記録一覧取得
               * （多読教材単位で読了したものを集約した一覧）
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_dhd5yl['get']['resBody'],
                  BasicHeaders,
                  Methods_dhd5yl['get']['status']
                >(prefix, `${prefix2}${PATH64}`, GET, option).json(),
              /**
               * 多読 学習記録一覧取得
               * （多読教材単位で読了したものを集約した一覧）
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<
                  Methods_dhd5yl['get']['resBody'],
                  BasicHeaders,
                  Methods_dhd5yl['get']['status']
                >(prefix, `${prefix2}${PATH64}`, GET, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH64}`,
            },
          };
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
