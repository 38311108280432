import type { AxiosError } from 'axios';

import type { Rule } from './sentryCapture';

// 可読性のためにformatをオフにします
// prettier-ignore
export const rules: Rule[] = [
    { path: /\/v1\/students\/[^/]+$/ },
    { path: /\/v1\/students\/[^/]+\/courses$/ },
    { path: /\/v1\/students\/[^/]+\/courses\/[^/]+$/ },
    { path: /\/v1\/students\/[^/]+\/courses\/[^/]+\/update_program_start_date_confirm$/ },
    { path: /\/v1\/students\/[^/]+\/courses\/[^/]+\/update_program_start_date$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/weeks$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/weeks\/[^/]+$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/study_menus$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/study_menus\/[^/]+$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/trainings$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/trainings\/[^/]+$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/weeks\/[^/]+\/trainings$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/weeks\/[^/]+\/trainings\/[^/]+$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/weeks\/[^/]+\/interview$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/weekly_interviews$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/weekly_interview_survey_questions$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/weekly_interview_survey_answers$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/weeks\/[^/]+\/study_status$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/weekly_study_status$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/entire_schedule$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/study_records$/ },
    { path: /\/v3\/students\/[^/]+\/schedules\/[^/]+\/study_records$/ },
    { path: /\/v2\/students\/[^/]+\/schedules\/[^/]+\/wpm_transitions$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/versant_exam_records$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/versant_exam_records\/[^/]+$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/other_exam_records$/ },
    { path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/other_exam_records\/[^/]+$/ },
    { path: /\/v1\/shadowing\/students\/[^/]+$/ },
    { path: /\/v1\/shadowing\/students\/[^/]+\/communication$/ },
    { path: /\/v1\/students\/[^/]+\/shadowing_corrections$/ },
    { path: /\/v1\/students\/[^/]+\/shadowing_corrections\/[^/]+$/ },
    { path: /\/v1\/students\/[^/]+\/shadowing_recordings\/[^/]+\/file$/ },
    { path: /\/v2\/shadowing\/students\/[^/]+$/ },
    { path: /\/v2\/shadowing\/students\/[^/]+\/communication$/ },
    { path: /\/v2\/students\/[^/]+\/shadowing_corrections$/ },
    { path: /\/v2\/shadowing_corrections\/[^/]+$/ },
    { path: /\/v2\/shadowing_recordings\/[^/]+\/file$/ },
    { path: /\/v1\/students\/[^/]+\/shadowing_notes$/ },
    { path: /\/v1\/students\/[^/]+\/shadowing_notes\/[^/]+$/ },
    { path: /\/v2\/students\/[^/]+\/shadowing_notes$/ },
    { path: /\/v2\/shadowing_notes\/[^/]+$/ },
];

const IgnoreRules = [
  {
    method: 'POST',
    path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/trainings$/,
    status: 422,
  },
  {
    method: 'PUT',
    path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/trainings\/[^/]+$/,
    status: 422,
  },
  {
    method: 'DELETE',
    path: /\/v1\/students\/[^/]+\/schedules\/[^/]+\/trainings\/[^/]+$/,
    status: 422,
  },
  {
    method: 'POST',
    path: /\/v1\/auth\/password_reset\/tokens$/,
    status: 422,
  },
  {
    method: 'PUT',
    path: /\/v2\/shadowing_corrections\/[^/]+$/,
    status: 409,
  },
  {
    method: 'POST',
    path: /\/v1\/auth\/login$/,
    status: 403,
  },
  {
    method: 'POST',
    path: /\/v1\/auth\/login$/,
    status: 404,
  },
  {
    method: 'POST',
    path: /\/v1\/auth\/apply$/,
    status: 404,
  },
  {
    method: 'POST',
    path: /\/v1\/auth\/apply$/,
    status: 422,
  },
  {
    method: 'GET',
    path: /\/v1\/incharge_students\?[^/]+$/,
    status: 422,
  },
  {
    method: 'GET',
    path: /\/v3\/schedules\/[^/]+\/entire_schedule$/,
    status: 404,
  },
  {
    method: 'GET',
    path: /^.*$/,
    status: 401,
  },
  {
    method: 'PUT',
    path: /^.*$/,
    status: 401,
  },
  {
    method: 'POST',
    path: /^.*$/,
    status: 401,
  },
  {
    method: 'DELETE',
    path: /^.*$/,
    status: 401,
  },
  {
    method: 'GET',
    path: /^.*$/,
    status: 0,
  },
  {
    method: 'PUT',
    path: /^.*$/,
    status: 0,
  },
  {
    method: 'POST',
    path: /^.*$/,
    status: 0,
  },
  {
    method: 'DELETE',
    path: /^.*$/,
    status: 0,
  },
];

export const isIgnoreHttpException = (error: AxiosError) =>
  IgnoreRules.find(
    (item) =>
      item.method === error.config?.method?.toUpperCase() &&
      item.path.test(error.config.url ?? '') &&
      item.status === error.response?.status
  ) != null;
