'use client';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useDesignSystem } from '~/hooks/useDesignSystem';
import { getTheme } from '~/styles/themes';

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { theme } = useDesignSystem();

  useEffect(() => {
    if (theme == null) return;
    window.document.documentElement.classList.add(theme);
    return () => {
      window.document.documentElement.classList.remove(theme);
    };
  }, [theme]);

  return (
    <MuiThemeProvider theme={getTheme(theme)}>{children}</MuiThemeProvider>
  );
};
