import { useGlobalState, mutateGlobal } from '@progrit/frontend-common';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';

export class CancelRouteChangeThrowable {} // routeChangeStartでキャンセルさせるためにthrowさせるクラス

export type ConfirmType = 'interview';

const CONFIRM_NAVIGATION_KEY = 'CONFIRM_NAVIGATION_KEY';

interface State {
  resolve?: (result: boolean) => void;
  shouldConfirm?: boolean;
  type: ConfirmType;
}

export const useConfirmNavigation = (type: ConfirmType) => {
  const { data, mutate } = useGlobalState<State>(CONFIRM_NAVIGATION_KEY, {
    type,
  });

  const confirmNavigation = useCallback(async () => {
    let resolve: (result: boolean) => void;

    const promise = new Promise<boolean>((_resolve) => (resolve = _resolve));
    await mutate((prev) => ({
      ...prev,
      resolve: async (result) => {
        resolve?.(result);
        await mutate((prev) => ({
          ...prev,
          resolve: undefined,
          type,
        }));
      },
      type,
    }));

    return await promise;
  }, [mutate, type]);

  const isConfirming = useMemo(
    () => !!data?.resolve && data?.type === type,
    [data, type]
  );

  return {
    confirmNavigation,
    isConfirming,
    resolve: data?.resolve,
    shouldConfirm: data?.shouldConfirm,
  };
};

export const setShouldConfirmNavigation = async (
  type: ConfirmType,
  shouldConfirm: boolean
) => {
  await mutateGlobal<State>(CONFIRM_NAVIGATION_KEY, (prev) => ({
    ...prev,
    type,
    shouldConfirm,
  }));
};

export const useConfirmNavigationProvider = (type: ConfirmType) => {
  const { confirmNavigation, shouldConfirm } = useConfirmNavigation(type);

  const router = useRouter();
  useEffect(() => {
    const handleRouteChangeStart = (toPath: string) => {
      if (shouldConfirm !== true) return;

      confirmNavigation().then(async (result) => {
        if (!result) return;
        router.events.off('routeChangeStart', handleRouteChangeStart); // この遷移がrouteChangeStartで再度確認されないようにoffにする
        await router.push(toPath);
      });
      throw new CancelRouteChangeThrowable();
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // NOTE: 任意の文字列をreturnValueに格納することで確認モーダルが表示される
      // 最近のブラウザではモーダルの文言をカスタマイズすることはできなくなった
      // https://stackoverflow.com/a/38880926/11953157
      if (shouldConfirm === true) e.returnValue = 'string';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [confirmNavigation, shouldConfirm, router]);
};
